"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  options: function options(h, conf, key) {
    var list = [];

    conf.__slot__.options.forEach(function (item) {
      list.push(h("el-option", {
        "attrs": {
          "label": item.label,
          "value": item.value,
          "disabled": item.disabled
        }
      }));
    });

    return list;
  }
};
exports.default = _default;