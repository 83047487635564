var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-details pos-order-details" },
    [
      _c("div", { staticClass: "header acea-row row-middle" }, [
        _c("div", { staticClass: "state" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "data" }, [
          _c("div", { staticClass: "order-num" }, [
            _vm._v("订单：" + _vm._s(_vm.orderInfo.orderId)),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.orderInfo.createTime)),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.$route.params.goname != "looks"
        ? _c("div", { staticClass: "remarks acea-row row-between-wrapper" }, [
            _c("span", { staticClass: "iconfont iconios-flag" }),
            _vm._v(" "),
            _c("input", {
              staticClass: "line1",
              staticStyle: { "text-align": "left" },
              attrs: {
                type: "button",
                value: _vm.orderInfo.remark
                  ? _vm.orderInfo.remark
                  : "订单未备注，点击添加备注信息",
              },
              on: {
                click: function ($event) {
                  return _vm.modify(1)
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "orderingUser acea-row row-middle" }, [
        _c("span", { staticClass: "iconfont iconmd-contact" }),
        _vm._v(_vm._s(_vm.orderInfo.realName) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.orderInfo.realName)),
          _c("span", { staticClass: "phone" }, [
            _vm._v(_vm._s(_vm.orderInfo.userPhone)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.orderInfo.userAddress))]),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pos-order-goods" },
        _vm._l(_vm.orderInfo.orderInfo, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "goods acea-row row-between-wrapper" },
            [
              _c(
                "div",
                { staticClass: "picTxt acea-row row-between-wrapper" },
                [
                  _c("div", { staticClass: "pictrue" }, [
                    _c("img", { attrs: { src: item.info.image } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("div", { staticClass: "info line2" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.info.productName) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "attr overflow" }, [
                      _vm._v(_vm._s(item.info.sku)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "money" }, [
                _c("div", { staticClass: "x-money" }, [
                  _vm._v("￥" + _vm._s(item.info.price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "num" }, [
                  _vm._v("x" + _vm._s(item.info.payNum)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "public-total" }, [
        _vm._v(
          "\n    共" + _vm._s(_vm.orderInfo.totalNum) + "件商品，应支付\n    "
        ),
        _c("span", { staticClass: "money" }, [
          _vm._v("￥" + _vm._s(_vm.orderInfo.payPrice)),
        ]),
        _vm._v(" ( 邮费 ¥" + _vm._s(_vm.orderInfo.payPostage) + ")\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("订单编号：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter acea-row row-middle row-right" }, [
            _vm._v("\n        " + _vm._s(_vm.orderInfo.orderId) + "\n        "),
            _c(
              "span",
              {
                staticClass: "copy copy-data",
                attrs: { "data-clipboard-text": _vm.orderInfo.orderId },
              },
              [_vm._v("复制")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("下单时间：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v(_vm._s(_vm.orderInfo.createTime)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("支付状态：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.orderInfo.statusStr.value) + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("支付方式：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v(_vm._s(_vm.orderInfo.payTypeStr)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("买家留言：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v(_vm._s(_vm.orderInfo.mark)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("支付金额：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.totalPrice)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("优惠券抵扣：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v("-￥" + _vm._s(_vm.orderInfo.couponPrice)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item acea-row row-between" }, [
          _c("div", [_vm._v("运费：")]),
          _vm._v(" "),
          _c("div", { staticClass: "conter" }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.payPostage)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actualPay acea-row row-right" }, [
          _vm._v("\n      实付款："),
          _c("span", { staticClass: "money font-color-red" }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.payPrice)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.orderInfo.deliveryType === "express"
        ? _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "item acea-row row-between" }, [
              _c("div", [_vm._v("配送方式：")]),
              _vm._v(" "),
              _vm.orderInfo.deliveryType === "express"
                ? _c("div", { staticClass: "conter" }, [
                    _vm._v("\n        快递\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.deliveryType === "send"
                ? _c("div", { staticClass: "conter" }, [_vm._v("送货")])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item acea-row row-between" }, [
              _vm.orderInfo.deliveryType === "express"
                ? _c("div", [_vm._v("快递公司：")])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.deliveryType === "send"
                ? _c("div", [_vm._v("送货人：")])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "conter" }, [
                _vm._v(_vm._s(_vm.orderInfo.deliveryName)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item acea-row row-between" }, [
              _vm.orderInfo.deliveryType === "express"
                ? _c("div", [_vm._v("快递单号：")])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.deliveryType === "send"
                ? _c("div", [_vm._v("送货人电话：")])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "conter" }, [
                _vm._v("\n        " + _vm._s(_vm.orderInfo.deliveryId)),
                _c(
                  "span",
                  {
                    staticClass: "copy copy-data",
                    attrs: { "data-clipboard-text": _vm.orderInfo.deliveryId },
                  },
                  [_vm._v("复制")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "1.2rem" } }),
      _vm._v(" "),
      _vm.$route.params.goname != "looks"
        ? _c(
            "div",
            { staticClass: "footer acea-row row-right row-middle" },
            [
              _c("div", { staticClass: "more" }),
              _vm._v(" "),
              _vm.types === "unPaid"
                ? _c(
                    "div",
                    {
                      staticClass: "bnt cancel",
                      on: {
                        click: function ($event) {
                          return _vm.modify(0)
                        },
                      },
                    },
                    [_vm._v("\n      一键改价\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.types === "refunding"
                ? _c(
                    "div",
                    {
                      staticClass: "bnt cancel",
                      on: {
                        click: function ($event) {
                          return _vm.modify(0)
                        },
                      },
                    },
                    [_vm._v("\n      立即退款\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bnt cancel",
                  on: {
                    click: function ($event) {
                      return _vm.modify(1)
                    },
                  },
                },
                [_vm._v("订单备注")]
              ),
              _vm._v(" "),
              _vm.types == "notShipped" &&
              _vm.orderInfo.shippingType !== 2 &&
              _vm.orderInfo.refundStatus !== 2
                ? _c(
                    "router-link",
                    {
                      staticClass: "bnt delivery",
                      attrs: {
                        to:
                          "/javaMobile/orderDelivery/" +
                          _vm.orderInfo.orderId +
                          "/" +
                          _vm.orderInfo.id,
                      },
                    },
                    [_vm._v("去发货")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.types === "toBeWrittenOff" &&
              _vm.orderInfo.shippingType === 2 &&
              _vm.isWriteOff &&
              _vm.orderInfo.refundStatus === 0 &&
              _vm.orderInfo.paid == true
                ? _c(
                    "router-link",
                    {
                      staticClass: "bnt delivery",
                      attrs: { to: "/operation/systemStore/orderCancellation" },
                    },
                    [_vm._v("去核销\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("PriceChange", {
        attrs: {
          change: _vm.change,
          orderInfo: _vm.orderInfo,
          status: _vm.status,
        },
        on: {
          closechange: function ($event) {
            return _vm.changeclose($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [
      _c("img", { attrs: { src: require("../../../assets/imgs/line.jpg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }