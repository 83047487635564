"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Home = _interopRequireDefault(require("@/components/FormGenerator/index/Home.vue"));

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "edit"
  components: {
    configList: _Home.default
  },
  props: {
    editData: {
      type: Object,
      default: {}
    },
    isCreate: {
      type: Number,
      default: 0 // 0=创建，1=编辑

    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handlerGetFormConfigData: function handlerGetFormConfigData(formConfigData) {
      formConfigData.id ? this.handlerEdit(formConfigData) : this.handlerSave(formConfigData);
    },
    handlerSave: function handlerSave(pram) {
      var _this = this;

      systemFormConfigApi.getFormConfigSave(pram).then(function (data) {
        _this.$message.success('创建表单配置成功');

        setTimeout(function () {
          _this.$emit('hideDialog');
        }, 800);
      });
    },
    handlerEdit: function handlerEdit(pram) {
      var _this2 = this;

      systemFormConfigApi.getFormConfigEdit(pram).then(function (data) {
        _this2.$message.success('编辑表单配置成功');

        setTimeout(function () {
          _this2.$emit('hideDialog');
        }, 800);
      });
    }
  }
};
exports.default = _default;