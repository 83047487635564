"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _marketing = require("@/api/marketing");

var _settings = require("@/settings");

var _permission = require("@/utils/permission");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = {
  name: 'CouponList',
  data: function data() {
    return {
      Loading: false,
      dialogVisible: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        status: '',
        name: ''
      },
      tableFromIssue: {
        page: 1,
        limit: 10,
        couponId: ''
      },
      issueData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 领取记录
    receive: function receive(row) {
      this.dialogVisible = true;
      this.tableFromIssue.couponId = row.id;
      this.getIssueList();
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this = this;

      this.Loading = true;
      (0, _marketing.couponUserListApi)(this.tableFromIssue).then(function (res) {
        _this.issueData.data = res.list;
        _this.issueData.total = res.total;
        _this.Loading = false;
      }).catch(function (res) {
        _this.Loading = false;

        _this.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    },
    // 列表
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _marketing.marketingListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;

      (0, _marketing.couponIssueStatusApi)({
        id: row.id,
        status: row.status
      }).then(function () {
        _this3.$message.success('修改成功');

        _this3.getList();
      }).catch(function () {
        row.status = !row.status;
      });
    },
    handleDelMenu: function handleDelMenu(rowData) {
      var _this4 = this;

      this.$confirm('确定删除当前数据?').then(function () {
        (0, _marketing.couponDeleteApi)({
          id: rowData.id
        }).then(function (data) {
          _this4.$message.success('删除成功');

          _this4.getList();
        });
      });
    }
  }
};
exports.default = _default;