"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _marketing = require("@/api/marketing");

var _store = require("@/api/store");

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "creatCoupon",
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24); //不限制未来时间
          return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + 600 * 8.64e7; //限制未来时间
        }
      },
      loading: false,
      threshold: false,
      termTime: [],
      props2: {
        children: 'child',
        label: 'name',
        value: 'id',
        checkStrictly: true,
        emitPath: false
      },
      couponType: 0,
      term: 'termday',
      merCateList: [],
      // 商户分类筛选
      ruleForm: {
        useType: 1,
        isFixedTime: false,
        name: '',
        money: 1,
        minPrice: 1,
        day: null,
        isForever: false,
        primaryKey: '',
        type: 2,
        isLimited: false,
        useStartTime: '',
        // 使用
        useEndTime: '',
        // 结束
        receiveStartTime: '',
        //领取
        receiveEndTime: '',
        sort: 0,
        total: 1,
        status: false,
        checked: []
      },
      isForeverTime: [],
      rules: {
        name: [{
          required: true,
          message: '请输入优惠券名称',
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: '请输入使用有效期限（天）',
          trigger: 'blur'
        }],
        money: [{
          required: true,
          message: '请输入优惠券面值',
          trigger: 'blur'
        }],
        primaryKey: [{
          required: true,
          message: '请选择品类',
          trigger: 'change'
        }],
        checked: [{
          required: true,
          message: '请至少选择一个商品',
          trigger: 'change',
          type: 'array'
        }],
        isForeverTime: [{
          required: true,
          message: '请选择领取时间',
          trigger: 'change',
          type: 'array'
        }],
        total: [{
          required: true,
          message: '请输入发布数量',
          trigger: 'blur'
        }],
        minPrice: [{
          required: true,
          message: '请输入最低消费',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getCategorySelect();
    if (this.$route.params.id) this.getInfo();
  },
  methods: {
    handleTimestamp: function handleTimestamp() {},
    // 商品分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;

      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this2.merCateList = res;

        _this2.merCateList.map(function (item) {
          _this2.$set(item, 'disabled', true);
        });
      });
    },
    getInfo: function getInfo() {
      var _this3 = this;

      this.loading = true;
      (0, _marketing.couponInfoApi)({
        id: this.$route.params.id
      }).then(function (res) {
        var info = res.coupon;
        _this3.ruleForm = {
          useType: info.useType,
          isFixedTime: info.isFixedTime,
          isForever: info.isForever,
          name: info.name,
          money: info.money,
          minPrice: info.minPrice,
          day: info.day,
          type: info.type,
          isLimited: info.isLimited,
          sort: info.sort,
          total: info.total,
          status: info.status,
          primaryKey: Number(info.primaryKey),
          checked: res.product || []
        };
        info.minPrice == 0 ? _this3.threshold = false : _this3.threshold = true;
        info.isForever ? _this3.isForeverTime = [info.receiveStartTime, info.receiveEndTime] : _this3.isForeverTime = [];
        info.isFixedTime && info.useStartTime && info.useEndTime ? _this3.termTime = [info.useStartTime, info.useEndTime] : _this3.termTime = [];
        _this3.loading = false;
      }).catch(function (res) {
        _this3.loading = false;

        _this3.$message.error(res.message);
      });
    },
    handleRemove: function handleRemove(i) {
      this.ruleForm.checked.splice(i, 1);
    },
    changeGood: function changeGood() {
      var _this = this;

      this.$modalGoodList(function (row) {
        _this.ruleForm.checked = row;
      }, 'many', _this.ruleForm.checked);
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this4 = this;

      if (this.ruleForm.isFixedTime && !this.termTime || this.ruleForm.isFixedTime && !this.termTime.length) return this.$message.warning("请选择使用有效期限");
      if (this.ruleForm.isForever && !this.isForeverTime || this.ruleForm.isForever && !this.isForeverTime.length) return this.$message.warning("请选择请选择领取时间");
      if (this.ruleForm.useType === 2) this.ruleForm.primaryKey = this.ruleForm.checked.map(function (item) {
        return item.id;
      }).join(',');
      if (this.ruleForm.useType === 1) this.ruleForm.primaryKey = '';
      if (!this.threshold) this.ruleForm.minPrice = 0;
      if (!this.ruleForm.isLimited) this.ruleForm.total = 0;
      this.ruleForm.isFixedTime && this.termTime.length ? (this.ruleForm.useStartTime = this.termTime[0], this.ruleForm.day = null) : this.ruleForm.useStartTime = '';
      this.ruleForm.isFixedTime && this.termTime.length ? (this.ruleForm.useEndTime = this.termTime[1], this.ruleForm.day = null) : this.ruleForm.useEndTime = '';
      this.ruleForm.isForever && this.isForeverTime.length ? this.ruleForm.receiveStartTime = this.isForeverTime[0] : this.ruleForm.receiveStartTime = '';
      this.ruleForm.isForever && this.isForeverTime.length ? this.ruleForm.receiveEndTime = this.isForeverTime[1] : this.ruleForm.receiveEndTime = '';
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          (0, _marketing.couponSaveApi)(_this4.ruleForm).then(function () {
            _this4.$message.success("新增成功");

            _this4.loading = false;
            setTimeout(function () {
              _this4.$router.push({
                path: "/marketing/coupon/list"
              });
            }, 200);
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          _this4.loading = false;
          return false;
        }
      });
    })
  }
};
exports.default = _default;