var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("config-list", {
        attrs: { "edit-data": _vm.editData, "is-create": _vm.isCreate },
        on: { getFormConfigDataResult: _vm.handlerGetFormConfigData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }