"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var roleApi = _interopRequireWildcard(require("@/api/role.js"));

var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));

var _validate = require("@/utils/validate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default2 = {
  // name: "edit"
  components: {},
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    var _this = this;

    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请填写手机号'));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号格式不正确!'));
      } else {
        callback();
      }
    };

    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.pram.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      constants: this.$constants,
      pram: {
        account: null,
        level: null,
        pwd: null,
        repwd: null,
        realName: null,
        roles: [],
        status: null,
        id: null,
        phone: null
      },
      roleList: [],
      rules: {
        account: [{
          required: true,
          message: '请填管理员账号',
          trigger: ['blur', 'change']
        }],
        pwd: [{
          required: true,
          message: '请填管理员密码',
          trigger: ['blur', 'change']
        }],
        repwd: [{
          required: true,
          message: '确认密码密码',
          validator: validatePass,
          trigger: ['blur', 'change']
        }],
        realName: [{
          required: true,
          message: '管理员姓名',
          trigger: ['blur', 'change']
        }],
        roles: [{
          required: true,
          message: '管理员身份',
          trigger: ['blur', 'change']
        }],
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: ['blur', 'change']
        }]
      }
    };
  },
  mounted: function mounted() {
    this.initEditData();
    this.handleGetRoleList();
  },
  methods: {
    close: function close() {
      this.$emit('hideEditDialog');
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;

      var _pram = {
        page: 1,
        limit: this.constants.page.limit[4],
        status: 1
      };
      roleApi.getRoleList(_pram).then(function (data) {
        _this2.roleList = data;
        var arr = [];
        data.list.forEach(function (item) {
          arr.push(item.id);
        });

        if (!arr.includes(Number.parseInt(_this2.pram.roles))) {
          _this2.$set(_this2.pram, 'roles', []);
        }
      });
    },
    initEditData: function initEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
          account = _this$editData.account,
          realName = _this$editData.realName,
          roles = _this$editData.roles,
          level = _this$editData.level,
          status = _this$editData.status,
          id = _this$editData.id,
          phone = _this$editData.phone;
      this.pram.account = account;
      this.pram.realName = realName;
      var _roles = [];

      if (roles.length > 0 && !roles.includes(',')) {
        //如果权限id集合有长度并且是只有一个，就将它Push进_roles这个数组
        _roles.push(Number.parseInt(roles));
      } else {
        //否则就将多个id集合解构以后push进roles并且转换为整型
        _roles.push.apply(_roles, _toConsumableArray(roles.split(',').map(function (item) {
          return Number.parseInt(item);
        })));
      }

      this.pram.roles = _roles;
      this.pram.status = status;
      this.pram.id = id;
      this.pram.phone = phone;
      this.rules.pwd = [];
      this.rules.repwd = [];
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this3 = this;

      this.$refs[form].validate(function (valid) {
        if (!valid) return;

        if (_this3.isCreate === 0) {
          _this3.handlerSave();
        } else {
          _this3.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this4 = this;

      systemAdminApi.adminAdd(this.pram).then(function (data) {
        _this4.$message.success('创建管理员成功');

        _this4.$emit('hideEditDialog');
      });
    },
    handlerEdit: function handlerEdit() {
      var _this5 = this;

      this.pram.roles = this.pram.roles.join(',');
      systemAdminApi.adminUpdate(this.pram).then(function (data) {
        _this5.$message.success('更新管理员成功');

        _this5.$emit('hideEditDialog');
      });
    },
    rulesSelect: function rulesSelect(selectKeys) {
      this.pram.rules = selectKeys;
    },
    handlerPwdInput: function handlerPwdInput(val) {
      var _this6 = this;

      if (!val) {
        this.rules.pwd = [];
        this.rules.repwd = [];
        return;
      }

      this.rules.pwd = [{
        required: true,
        message: '请填管理员密码',
        trigger: ['blur', 'change']
      }, {
        min: 6,
        max: 20,
        message: '长度6-20个字符',
        trigger: ['blur', 'change']
      }];
      this.rules.repwd = [{
        required: true,
        message: '两次输入密码不一致',
        validator: function validator(rule, value, callback) {
          if (value === '') {
            callback(new Error('两次输入密码不一致!'));
          } else if (value !== _this6.pram.pwd) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        },
        trigger: ['blur', 'change']
      }];
    }
  }
};
exports.default = _default2;