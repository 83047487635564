var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "statistical-page" },
    [
      _c("div", { staticClass: "navs" }, [
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            {
              staticClass: "item",
              class: _vm.time == "today" ? "on" : "",
              on: {
                click: function ($event) {
                  return _vm.setTime("today")
                },
              },
            },
            [_vm._v("\n        今天\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item",
              class: _vm.time == "yesterday" ? "on" : "",
              on: {
                click: function ($event) {
                  return _vm.setTime("yesterday")
                },
              },
            },
            [_vm._v("\n        昨天\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item",
              class: _vm.time == "lately7" ? "on" : "",
              on: {
                click: function ($event) {
                  return _vm.setTime("lately7")
                },
              },
            },
            [_vm._v("\n        最近7天\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item",
              class: _vm.time == "month" ? "on" : "",
              on: {
                click: function ($event) {
                  return _vm.setTime("month")
                },
              },
            },
            [_vm._v("\n        本月\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item",
              class: _vm.time == "date" ? "on" : "",
              on: { click: _vm.dateTitle },
            },
            [_vm._v("\n        自定义\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.title) +
              _vm._s(this.where.type == 1 ? "营业额（元）" : "订单量（份）") +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "money" }, [_vm._v(_vm._s(_vm.time_price))]),
        _vm._v(" "),
        _c("div", { staticClass: "increase acea-row row-between-wrapper" }, [
          _c("div", [
            _vm._v("\n        " + _vm._s(_vm.title) + "增长率："),
            _c(
              "span",
              { class: _vm.increase_time_status === 1 ? "red" : "green" },
              [
                _vm._v(
                  _vm._s(_vm.increase_time_status === 1 ? "" : "-") +
                    _vm._s(_vm.growth_rate) +
                    "%\n          "
                ),
                _c("span", {
                  staticClass: "iconfont",
                  class:
                    _vm.increase_time_status === 1
                      ? "icon-xiangshang1"
                      : "icon-xiangxia2",
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n        " + _vm._s(_vm.title) + "增长："),
            _c(
              "span",
              { class: _vm.increase_time_status === 1 ? "red" : "green" },
              [
                _vm._v(
                  _vm._s(Number(_vm.increase_time).toFixed(2)) + "\n          "
                ),
                _c("span", {
                  staticClass: "iconfont",
                  class:
                    _vm.increase_time_status === 1
                      ? "icon-xiangshang1"
                      : "icon-xiangxia2",
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chart" },
        [
          _c("div", { staticClass: "company" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.where.type === 1 ? "单位（元）" : "单位（份）") +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("ECharts", { attrs: { options: _vm.polar } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("statistics-data", { attrs: { list: _vm.list } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "calendar-wrapper",
          class: _vm.current === true ? "on" : "",
        },
        [
          _c(
            "div",
            { staticClass: "calendar" },
            [
              _c("Calendar", {
                ref: "calendar",
                attrs: {
                  clean: _vm.clean,
                  lunar: _vm.lunar,
                  range: _vm.isrange,
                  multi: _vm.ismulti,
                  value: _vm.value,
                  weekSwitch: _vm.weekSwitch,
                  monthRange: _vm.monthRange,
                  rangeMonthFormat: "yyyy-mm-dd",
                  monFirst: "",
                  responsive: "",
                  begin: [1992, 5, 20],
                  end: [2049, 5, 20],
                },
                on: { select: _vm.select, next: _vm.next, prev: _vm.prev },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.current === true,
            expression: "current === true",
          },
        ],
        staticClass: "maskModel",
        on: {
          touchmove: function ($event) {
            $event.preventDefault()
          },
          click: _vm.close,
        },
      }),
      _vm._v(" "),
      _c("Loading", { attrs: { loaded: _vm.loaded, loading: _vm.loading } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }