var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.multiple
        ? _c(
            "div",
            { staticClass: "acea-row" },
            [
              _vm._l(_vm.imageList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "pictrue",
                    attrs: { draggable: "false" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.handleDragStart($event, item)
                      },
                      dragover: function ($event) {
                        $event.preventDefault()
                        return _vm.handleDragOver($event, item)
                      },
                      dragenter: function ($event) {
                        return _vm.handleDragEnter($event, item)
                      },
                      dragend: function ($event) {
                        return _vm.handleDragEnd($event, item)
                      },
                    },
                  },
                  [
                    _c("img", { attrs: { src: item.sattDir } }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-error btndel",
                      on: {
                        click: function ($event) {
                          return _vm.handleRemove(index)
                        },
                      },
                    }),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageList.length < 20,
                      expression: "imageList.length<20",
                    },
                  ],
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("2")
                    },
                  },
                },
                [_vm._m(0)]
              ),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass: "upLoadPicBox",
              on: {
                click: function ($event) {
                  return _vm.modalPicTap("1")
                },
              },
            },
            [
              _vm.image
                ? _c("div", { staticClass: "pictrue" }, [
                    _c("img", { attrs: { src: _vm.image } }),
                  ])
                : _c("div", { staticClass: "upLoad" }, [
                    _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                  ]),
            ]
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传图片",
            visible: _vm.visible,
            width: "896px",
            "before-close": _vm.handleClose,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("upload-index", {
                attrs: { checkedMore: _vm.imageList, isMore: _vm.isMore },
                on: { getImage: _vm.getImage },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upLoad" }, [
      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }