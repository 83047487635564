var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-row",
        { staticClass: "dashboard-console-grid", attrs: { gutter: 24 } },
        [
          _vm.checkPermi(["admin:user:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/user/index" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-user",
                            staticStyle: { color: "#69c0ff" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("会员管理")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:system:config:info"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/operation/setting" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-setting",
                            staticStyle: { color: "#95de64" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("系统设置")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:product:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/store/index" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-goods",
                            staticStyle: { color: "#ff9c6e" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("商品")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:order:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/order/index" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-order",
                            staticStyle: { color: "#b37feb" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("订单管理")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:pass:login"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { path: "/operation/systemSms/config" },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-message",
                            staticStyle: { color: "#ffd666" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("短信配置")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:article:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/content/articleManager" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-notebook-1",
                            staticStyle: { color: "#5cdbd3" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("文章管理")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:retail:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/distribution/index" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-finance",
                            staticStyle: { color: "#ff85c0" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("分销管理")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:coupon:list"])
            ? _c(
                "el-col",
                _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/marketing/coupon/list" } } },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-ticket",
                            staticStyle: { color: "#ffc069" },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("优惠券")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }