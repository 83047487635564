"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var _configCategotyEdit = _interopRequireDefault(require("@/views/maintain/devconfig/configCategotyEdit.vue"));

var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));

var _configList = _interopRequireDefault(require("./configList"));

var _formConfig = _interopRequireDefault(require("@/views/maintain/formConfig"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "configCategroy"
  components: {
    edit: _configCategotyEdit.default,
    configList: _configList.default,
    formConfigList: _formConfig.default
  },
  props: {},
  data: function data() {
    return {
      constants: this.$constants,
      searchPram: {
        status: null,
        type: null
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        prent: {},
        // 父级对象
        data: {}
      },
      treeList: [],
      listPram: {
        pid: 0,
        type: this.$constants.categoryType[5].value,
        status: null,
        name: null,
        page: this.$constants.page.page,
        limit: this.$constants.page.limit[1]
      },
      configFormSelectedDialog: {
        visible: false,
        currentData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetTreeList();
  },
  methods: {
    handlerOpenFormConfig: function handlerOpenFormConfig(rowData) {
      this.configFormSelectedDialog.currentData = rowData;
      this.configFormSelectedDialog.visible = true;
    },
    handlerGetList: function handlerGetList() {
      var _this = this;

      categoryApi.listCategroy(this.listPram).then(function (data) {
        // this.dataList = data
        _this.treeList = data.list;
      });
    },
    handlerOpenAdd: function handlerOpenAdd(rowData) {
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.data = {};
      this.editDialogConfig.biztype = this.biztype;
      this.editDialogConfig.visible = true;
    },
    handleEditMenu: function handleEditMenu(rowData) {
      this.editDialogConfig.isCreate = 1;
      this.editDialogConfig.data = rowData;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.visible = true;
    },
    handleDelMenu: function handleDelMenu(rowData) {
      var _this2 = this;

      this.$confirm('确定删除当前数据?').then(function () {
        categoryApi.deleteCategroy(rowData).then(function (data) {
          _this2.handlerGetTreeList();

          _this2.$message.success('删除成功');
        });
      });
    },
    hideEditDialog: function hideEditDialog() {
      var _this3 = this;

      setTimeout(function () {
        _this3.editDialogConfig.prent = {};
        _this3.editDialogConfig.type = 0;
        _this3.editDialogConfig.visible = false;

        _this3.handlerGetTreeList();
      }, 200);
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this4 = this;

      // status: this.selectModel?1:-1
      var _pram = {
        type: this.constants.categoryType[5].value,
        status: -1
      };
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this4.treeList = _this4.handleAddArrt(data);
      });
    },
    handleAddArrt: function handleAddArrt(treeData) {
      // let _result = this.addTreeListLabel(treeData)
      var _result = selfUtil.addTreeListLabel(treeData);

      return _result;
    },
    handlerSelectedRowData: function handlerSelectedRowData(rowData) {
      this.configFormSelectedDialog.currentData.extra = rowData.id;
    },
    handlerAddFormExtra: function handlerAddFormExtra() {
      var _this5 = this;

      categoryApi.updateCategroy(this.configFormSelectedDialog.currentData).then(function (data) {
        _this5.$message.success('关联表单成功');

        setTimeout(function () {
          _this5.configFormSelectedDialog.visible = false;

          _this5.handlerGetTreeList();
        }, 800);
      });
    }
  }
};
exports.default = _default;