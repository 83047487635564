"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _list = _interopRequireDefault(require("@/components/Category/list"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
var _default = {
  components: {
    categoryList: _list.default
  },
  data: function data() {
    return {
      constants: this.$constants
    };
  }
};
exports.default = _default;