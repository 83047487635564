"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.loadScriptQueue = loadScriptQueue;
var callbacks = {};
/**
 * 加载一个远程脚本
 * @param {String} src 一个远程脚本
 * @param {Function} callback 回调
 */

function loadScript(src, callback) {
  var existingScript = document.getElementById(src);

  var cb = callback || function () {};

  if (!existingScript) {
    callbacks[src] = [];
    var $script = document.createElement('script');
    $script.src = src;
    $script.id = src;
    $script.async = 1;
    document.body.appendChild($script);
    var onEnd = 'onload' in $script ? stdOnEnd.bind($script) : ieOnEnd.bind($script);
    onEnd($script);
  }

  callbacks[src].push(cb);

  function stdOnEnd(script) {
    var _this = this;

    script.onload = function () {
      _this.onerror = _this.onload = null;
      callbacks[src].forEach(function (item) {
        item(null, script);
      });
      delete callbacks[src];
    };

    script.onerror = function () {
      _this.onerror = _this.onload = null;
      cb(new Error("Failed to load ".concat(src)), script);
    };
  }

  function ieOnEnd(script) {
    var _this2 = this;

    script.onreadystatechange = function () {
      if (_this2.readyState !== 'complete' && _this2.readyState !== 'loaded') return;
      _this2.onreadystatechange = null;
      callbacks[src].forEach(function (item) {
        item(null, script);
      });
      delete callbacks[src];
    };
  }
}
/**
 * 顺序加载一组远程脚本
 * @param {Array} list 一组远程脚本
 * @param {Function} cb 回调
 */


function loadScriptQueue(list, cb) {
  var first = list.shift();
  list.length ? loadScript(first, function () {
    return loadScriptQueue(list, cb);
  }) : loadScript(first, cb);
}

var _default = loadScript;
exports.default = _default;