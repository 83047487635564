"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var storeRouter = {
  path: '/store',
  component: _layout.default,
  redirect: '/store/index',
  name: 'Store',
  meta: {
    title: '商品',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/index'));
      });
    },
    name: 'StoreIndex',
    meta: {
      title: '商品管理',
      icon: ''
    }
  }, {
    path: 'sort',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/sort/index'));
      });
    },
    name: 'Sort',
    meta: {
      title: '商品分类',
      icon: ''
    }
  }, {
    path: 'attr',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/storeAttr/index'));
      });
    },
    name: 'SortAttr',
    meta: {
      title: '商品规格',
      icon: ''
    }
  }, {
    path: 'comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/storeComment/index'));
      });
    },
    name: 'StoreComment',
    meta: {
      title: '商品评论',
      icon: ''
    }
  }, {
    path: 'list/creatProduct/:id?/:isDisabled?',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/creatStore/index'));
      });
    },
    name: 'SortCreat',
    meta: {
      title: '商品添加',
      noCache: true,
      activeMenu: "/store/index"
    },
    hidden: true
  }]
};
var _default = storeRouter;
exports.default = _default;