var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                size: "mini",
                "row-key": "cityId",
                "highlight-current-row": "",
                border: "",
                lazy: "",
                load: _vm.load,
                "header-cell-style": { fontWeight: "bold" },
                "tree-props": { children: "child", hasChildren: "hasChildren" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "cityId", label: "编号", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "parentName",
                  label: "上级名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", "min-width": "250", label: "地区名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", "min-width": "80", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:system:city:update"],
                                expression: "['admin:system:city:update']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editCity(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.formShow
            ? _c("parser", {
                attrs: {
                  "form-conf": _vm.formConf,
                  "form-edit-data": _vm.formData,
                  "is-edit": _vm.isCreate === 1,
                },
                on: { submit: _vm.submit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }