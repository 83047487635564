"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

var logistics = _interopRequireWildcard(require("@/api/logistics.js"));

var _permission = require("@/utils/permission");

var _validate = require("@/utils/validate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = {
  name: 'CompanyList',
  components: {
    parser: _Parser.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      // 表单
      formConf: {
        fields: []
      },
      form: {
        keywords: ''
      },
      tableData: {},
      page: 1,
      limit: 20,
      loading: false,
      dialogVisible: false,
      fromType: 'add',
      formData: {
        status: false
      },
      isCreate: 0,
      formShow: false,
      editId: 0,
      rules: {
        sort: [{
          required: true,
          message: '请输入排序',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '请输入月结账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入月结密码',
          trigger: 'blur'
        }],
        netName: [{
          required: true,
          message: '请输入网点名称',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getExpressList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerSearch: function handlerSearch() {
      this.page = 1;
      this.getExpressList();
    },
    //  获取物流公司列表
    getExpressList: function getExpressList() {
      var _this = this;

      this.loading = true;
      logistics.expressList({
        page: this.page,
        limit: this.limit,
        keywords: this.form.keywords
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 物流开关
    bindStatus: function bindStatus(item) {
      var _this2 = this;

      logistics.expressUpdateShow({
        account: item.account,
        code: item.code,
        id: item.id,
        isShow: item.isShow,
        name: item.name,
        sort: item.sort
      }).then(function (res) {
        _this2.$message.success('操作成功'); // this.getExpressList()

      }).catch(function () {
        item.isShow = !item.isShow;
      });
    },
    // 分页
    pageChange: function pageChange(e) {
      this.page = e;
      this.getExpressList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.limit = e;
      this.getExpressList();
    },
    // 添加物流公司
    addExpress: function addExpress() {
      var _this3 = this;

      logistics.expressSyncApi().then(function (data) {
        _this3.page = 1;

        _this3.getExpressList();
      });
    },
    // 删除物流公司
    bindDelete: function bindDelete(item) {
      var _this4 = this;

      this.$modalSure().then(function () {
        logistics.expressDelete({
          id: item.id
        }).then(function (res) {
          _this4.$message.success('删除成功');

          _this4.getExpressList();
        });
      });
    },
    // 表单提交
    submit: (0, _validate.Debounce)(function (formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          logistics.expressUpdate(_this5.formData).then(function (res) {
            _this5.$message.success('操作成功');

            _this5.handleClose();

            _this5.getExpressList();
          });
        } else {
          return false;
        }
      });
    }),
    //  关闭模态框
    handleClose: function handleClose(done) {
      this.formShow = false; // this.formData = {}

      this.formConf.fields = [];
      this.dialogVisible = false;
      this.isCreate = 0;
    },
    // 编辑
    bindEdit: function bindEdit(item) {
      var _this6 = this;

      this.dialogVisible = true;
      this.editId = item.id;
      logistics.expressInfo({
        id: item.id
      }).then(function (res) {
        _this6.formData = res;
      });
    }
  }
};
exports.default = _default;