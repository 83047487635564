"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _parser = require("@babel/parser");

var _clipboard = _interopRequireDefault(require("clipboard"));

var _fileSaver = require("file-saver");

var _html = require("@/components/FormGenerator/components/generator/html");

var _js = require("@/components/FormGenerator/components/generator/js");

var _css = require("@/components/FormGenerator/components/generator/css");

var _index = require("../utils/index");

var _ResourceDialog = _interopRequireDefault(require("./ResourceDialog"));

var _loadMonaco = _interopRequireDefault(require("../utils/loadMonaco"));

var _loadBeautifier = _interopRequireDefault(require("../utils/loadBeautifier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var editorObj = {
  html: null,
  js: null,
  css: null
};
var mode = {
  html: 'html',
  js: 'javascript',
  css: 'css'
};
var beautifier;
var monaco;
var _default = {
  components: {
    ResourceDialog: _ResourceDialog.default
  },
  props: ['formData', 'generateConf'],
  data: function data() {
    return {
      activeTab: 'html',
      htmlCode: '',
      jsCode: '',
      cssCode: '',
      codeFrame: '',
      isIframeLoaded: false,
      isInitcode: false,
      // 保证open后两个异步只执行一次runcode
      isRefreshCode: false,
      // 每次打开都需要重新刷新代码
      resourceVisible: false,
      scripts: [],
      links: [],
      monaco: null
    };
  },
  computed: {
    resources: function resources() {
      return this.scripts.concat(this.links);
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    window.addEventListener('keydown', this.preventDefaultSave);
    var clipboard = new _clipboard.default('.copy-btn', {
      text: function text(trigger) {
        var codeStr = _this.generateCode();

        _this.$notify({
          title: '成功',
          message: '代码已复制到剪切板，可粘贴。',
          type: 'success'
        });

        return codeStr;
      }
    });
    clipboard.on('error', function (e) {
      _this.$message.error('代码复制失败');
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.preventDefaultSave);
  },
  methods: {
    preventDefaultSave: function preventDefaultSave(e) {
      if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
    },
    onOpen: function onOpen() {
      var _this2 = this;

      var type = this.generateConf.type;
      this.htmlCode = (0, _html.makeUpHtml)(this.formData, type);
      this.jsCode = (0, _js.makeUpJs)(this.formData, type);
      this.cssCode = (0, _css.makeUpCss)(this.formData);
      (0, _loadBeautifier.default)(function (btf) {
        beautifier = btf;
        _this2.htmlCode = beautifier.html(_this2.htmlCode, _index.beautifierConf.html);
        _this2.jsCode = beautifier.js(_this2.jsCode, _index.beautifierConf.js);
        _this2.cssCode = beautifier.css(_this2.cssCode, _index.beautifierConf.html);
        (0, _loadMonaco.default)(function (val) {
          monaco = val;

          _this2.setEditorValue('editorHtml', 'html', _this2.htmlCode);

          _this2.setEditorValue('editorJs', 'js', _this2.jsCode);

          _this2.setEditorValue('editorCss', 'css', _this2.cssCode);

          if (!_this2.isInitcode) {
            _this2.isRefreshCode = true;
            _this2.isIframeLoaded && (_this2.isInitcode = true) && _this2.runCode();
          }
        });
      });
    },
    onClose: function onClose() {
      this.isInitcode = false;
      this.isRefreshCode = false;
      this.isIframeLoaded = false;
    },
    iframeLoad: function iframeLoad() {
      if (!this.isInitcode) {
        this.isIframeLoaded = true;
        this.isRefreshCode && (this.isInitcode = true) && this.runCode();
      }
    },
    setEditorValue: function setEditorValue(id, type, codeStr) {
      var _this3 = this;

      if (editorObj[type]) {
        editorObj[type].setValue(codeStr);
      } else {
        editorObj[type] = monaco.editor.create(document.getElementById(id), {
          value: codeStr,
          theme: 'vs-dark',
          language: mode[type],
          automaticLayout: true
        });
      } // ctrl + s 刷新


      editorObj[type].onKeyDown(function (e) {
        if (e.keyCode === 49 && (e.metaKey || e.ctrlKey)) {
          _this3.runCode();
        }
      });
    },
    runCode: function runCode() {
      var jsCodeStr = editorObj.js.getValue();

      try {
        var ast = (0, _parser.parse)(jsCodeStr, {
          sourceType: 'module'
        });
        var astBody = ast.program.body;

        if (astBody.length > 1) {
          this.$confirm('js格式不能识别，仅支持修改export default的对象内容', '提示', {
            type: 'warning'
          });
          return;
        }

        if (astBody[0].type === 'ExportDefaultDeclaration') {
          var postData = {
            type: 'refreshFrame',
            data: {
              generateConf: this.generateConf,
              html: editorObj.html.getValue(),
              js: jsCodeStr.replace(_index.exportDefault, ''),
              css: editorObj.css.getValue(),
              scripts: this.scripts,
              links: this.links
            }
          };
          this.$refs.previewPage.contentWindow.postMessage(postData, location.origin);
        } else {
          this.$message.error('请使用export default');
        }
      } catch (err) {
        this.$message.error("js\u9519\u8BEF\uFF1A".concat(err));
      }
    },
    generateCode: function generateCode() {
      var html = (0, _html.vueTemplate)(editorObj.html.getValue());
      var script = (0, _html.vueScript)(editorObj.js.getValue());
      var css = (0, _html.cssStyle)(editorObj.css.getValue());
      return beautifier.html(html + script + css, _index.beautifierConf.html);
    },
    exportFile: function exportFile() {
      var _this4 = this;

      this.$prompt('文件名:', '导出文件', {
        inputValue: "".concat(+new Date(), ".vue"),
        closeOnClickModal: false,
        inputPlaceholder: '请输入文件名'
      }).then(function (_ref) {
        var value = _ref.value;
        if (!value) value = "".concat(+new Date(), ".vue");

        var codeStr = _this4.generateCode();

        var blob = new Blob([codeStr], {
          type: 'text/plain;charset=utf-8'
        });
        (0, _fileSaver.saveAs)(blob, value);
      });
    },
    showResource: function showResource() {
      this.resourceVisible = true;
    },
    setResource: function setResource(arr) {
      var scripts = [];
      var links = [];

      if (Array.isArray(arr)) {
        arr.forEach(function (item) {
          if (item.endsWith('.css')) {
            links.push(item);
          } else {
            scripts.push(item);
          }
        });
        this.scripts = scripts;
        this.links = links;
      } else {
        this.scripts = [];
        this.links = [];
      }
    }
  }
};
exports.default = _default;