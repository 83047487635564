var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "管理员账号", prop: "account" } },
            [
              _c("el-input", {
                attrs: { placeholder: "管理员账号" },
                model: {
                  value: _vm.pram.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "account", $$v)
                  },
                  expression: "pram.account",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "管理员密码", prop: "pwd" } },
            [
              _c("el-input", {
                attrs: { placeholder: "管理员密码", clearable: "" },
                on: { input: _vm.handlerPwdInput, clear: _vm.handlerPwdInput },
                model: {
                  value: _vm.pram.pwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "pwd", $$v)
                  },
                  expression: "pram.pwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pram.pwd
            ? _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "repwd" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "确认密码", clearable: "" },
                    model: {
                      value: _vm.pram.repwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "repwd", $$v)
                      },
                      expression: "pram.repwd",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "管理员姓名", prop: "realName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "管理员姓名" },
                model: {
                  value: _vm.pram.realName,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "realName", $$v)
                  },
                  expression: "pram.realName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "管理员身份", prop: "roles" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "身份", clearable: "", multiple: "" },
                  model: {
                    value: _vm.pram.roles,
                    callback: function ($$v) {
                      _vm.$set(_vm.pram, "roles", $$v)
                    },
                    expression: "pram.roles",
                  },
                },
                _vm._l(_vm.roleList.list, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.roleName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  prefix: "ios-contact-outline",
                  placeholder: "请输入手机号",
                  size: "large",
                },
                model: {
                  value: _vm.pram.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "phone", $$v)
                  },
                  expression: "pram.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                model: {
                  value: _vm.pram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "status", $$v)
                  },
                  expression: "pram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "admin:system:admin:update",
                        "admin:system:admin:save",
                      ],
                      expression:
                        "['admin:system:admin:update','admin:system:admin:save']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isCreate === 0 ? "確定" : "更新"))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }