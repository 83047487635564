var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入优惠券名称" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.useType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "useType", $$v)
                        },
                        expression: "ruleForm.useType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("通用券"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("商品券"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("品类券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.useType === 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择品类：", prop: "primaryKey" } },
                    [
                      _c("el-cascader", {
                        staticClass: "selWidth",
                        attrs: {
                          options: _vm.merCateList,
                          props: _vm.props2,
                          clearable: "",
                          "show-all-levels": false,
                        },
                        model: {
                          value: _vm.ruleForm.primaryKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "primaryKey", $$v)
                          },
                          expression: "ruleForm.primaryKey",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.useType === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品：", prop: "checked" } },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row" },
                        [
                          _vm.ruleForm.checked.length
                            ? _vm._l(
                                _vm.ruleForm.checked,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: { click: _vm.changeGood },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券面值", prop: "money" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, label: "描述文字" },
                    model: {
                      value: _vm.ruleForm.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "money", $$v)
                      },
                      expression: "ruleForm.money",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用门槛" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.threshold,
                        callback: function ($$v) {
                          _vm.threshold = $$v
                        },
                        expression: "threshold",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("无门槛"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("有门槛"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.threshold
                ? _c(
                    "el-form-item",
                    { attrs: { label: "优惠券最低消费", prop: "minPrice" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.minPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "minPrice", $$v)
                          },
                          expression: "ruleForm.minPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用有效期" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isFixedTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isFixedTime", $$v)
                        },
                        expression: "ruleForm.isFixedTime",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("天数"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("时间段"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限（天）", prop: "day" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 999, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.day,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "day", $$v)
                          },
                          expression: "ruleForm.day",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限", prop: "resource" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "picker-options": _vm.pickerOptions,
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.termTime,
                          callback: function ($$v) {
                            _vm.termTime = $$v
                          },
                          expression: "termTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取是否限时", prop: "isForever" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isForever,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isForever", $$v)
                        },
                        expression: "ruleForm.isForever",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限时"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限时"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isForever
                ? _c(
                    "el-form-item",
                    { attrs: { label: "领取时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { blur: _vm.handleTimestamp },
                        model: {
                          value: _vm.isForeverTime,
                          callback: function ($$v) {
                            _vm.isForeverTime = $$v
                          },
                          expression: "isForeverTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取方式", prop: "resource" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("手动领取"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("新人券"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("赠送券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否限量", prop: "isLimited" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isLimited,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isLimited", $$v)
                        },
                        expression: "ruleForm.isLimited",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限量"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isLimited
                ? _c(
                    "el-form-item",
                    { attrs: { label: "发布数量", prop: "total" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, label: "排序" },
                        model: {
                          value: _vm.ruleForm.total,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "total", $$v)
                          },
                          expression: "ruleForm.total",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, label: "排序" },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:coupon:save"],
                          expression: "['admin:coupon:save']",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }