"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

var _edit = _interopRequireDefault(require("./edit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "index"
  components: {
    edit: _edit.default
  },
  props: {
    selectModel: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keywords: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      editDialogConfig: {
        visible: false,
        editData: {},
        isCreate: 0
      },
      dataList: {
        list: [],
        total: 0
      },
      selectedConfigData: {}
    };
  },
  mounted: function mounted() {
    this.handlerGetList(this.listPram);
  },
  methods: {
    handlerSearch: function handlerSearch() {
      this.listPram.page = 1;
      this.handlerGetList(this.listPram);
    },
    handlerGetList: function handlerGetList(pram) {
      var _this = this;

      systemFormConfigApi.getFormConfigList(pram).then(function (data) {
        _this.dataList = data;
      });
    },
    handlerEditData: function handlerEditData(rowData, isCreate) {
      if (isCreate === 0) {
        this.editDialogConfig.editData = {};
      } else {
        this.editDialogConfig.editData = rowData;
      }

      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerHide: function handlerHide() {
      this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.visible = false;
      this.handlerGetList(this.listPram);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetList(this.listPram);
    },
    handleCurrentRowChange: function handleCurrentRowChange(rowData) {
      this.selectedConfigData = rowData;
    },
    handlerConfimSelect: function handlerConfimSelect() {
      this.$emit('selectedRowData', this.selectedConfigData);
    }
  }
};
exports.default = _default;