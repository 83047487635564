"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../utils/index");

var _db = require("../utils/db");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var id = (0, _db.getTreeNodeId)();
var _default = {
  components: {},
  inheritAttrs: false,
  props: [],
  data: function data() {
    return {
      id: id,
      formData: {
        label: undefined,
        value: undefined
      },
      rules: {
        label: [{
          required: true,
          message: '请输入选项名',
          trigger: 'blur'
        }],
        value: [{
          required: true,
          message: '请输入选项值',
          trigger: 'blur'
        }]
      },
      dataType: 'string',
      dataTypeOptions: [{
        label: '字符串',
        value: 'string'
      }, {
        label: '数字',
        value: 'number'
      }]
    };
  },
  computed: {},
  watch: {
    // eslint-disable-next-line func-names
    'formData.value': function formDataValue(val) {
      this.dataType = (0, _index.isNumberStr)(val) ? 'number' : 'string';
    },
    id: function id(val) {
      (0, _db.saveTreeNodeId)(val);
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onOpen: function onOpen() {
      this.formData = {
        label: undefined,
        value: undefined
      };
    },
    onClose: function onClose() {},
    close: function close() {
      this.$emit('update:visible', false);
    },
    handelConfirm: function handelConfirm() {
      var _this = this;

      this.$refs.elForm.validate(function (valid) {
        if (!valid) return;

        if (_this.dataType === 'number') {
          _this.formData.value = parseFloat(_this.formData.value);
        }

        _this.formData.id = _this.id++;

        _this.$emit('commit', _this.formData);

        _this.close();
      });
    }
  }
};
exports.default = _default;