"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _WriteOff = _interopRequireDefault(require("../components/WriteOff"));

var _wechat = require("@/libs/wechat");

var _order = require("@/api/order");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var NAME = "OrderCancellation";
var _default = {
  name: NAME,
  components: {
    WriteOff: _WriteOff.default
  },
  props: {},
  data: function data() {
    return {
      isWeixin: this.$wechat.isWeixin(),
      iShidden: true,
      orderInfo: null,
      verify_code: ""
    };
  },
  created: function created() {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/assets/js/media_750'));
    });
  },
  methods: {
    cancel: function cancel(res) {
      this.iShidden = res;
    },
    confirm: function confirm() {
      var _this = this;

      (0, _order.writeUpdateApi)(this.verify_code).then(function (res) {
        _this.iShidden = true;
        _this.verify_code = "";

        _this.$dialog.success(res.msg);
      }).catch(function (res) {
        _this.$dialog.error(res.msg);
      });
    },
    storeCancellation: function storeCancellation() {
      var _this2 = this;

      var ref = /[0-9]{10}/;
      if (!this.verify_code) return this.$dialog.error("请输入核销码");
      if (!ref.test(this.verify_code)) return this.$dialog.error("请输入正确的核销码");
      this.$dialog.loading.open("查询中");
      (0, _order.writeConfirmApi)(this.verify_code).then(function (res) {
        _this2.$dialog.loading.close();

        _this2.orderInfo = res;
        _this2.iShidden = false;
      }).catch(function (res) {
        _this2.$dialog.loading.close();

        _this2.verify_code = "";
        return _this2.$dialog.error(res.message);
      });
    },
    openQRCode: function openQRCode() {
      var that = this;
      (0, _wechat.wechatEvevt)("scanQRCode", {
        needResult: 1,
        scanType: ["qrCode", "barCode"]
      }).then(function (res) {
        if (res.resultStr) {
          that.verify_code = res.resultStr;
          that.storeCancellation();
        } else that.$dialog.error("没有扫描到什么！");
      }).catch(function (res) {
        if (res.is_ready) {
          res.wx.scanQRCode({
            needResult: 1,
            scanType: ["qrCode", "barCode"],
            success: function success(res) {
              that.verify_code = res.resultStr;
              that.storeCancellation();
            },
            fail: function fail(res) {
              if (res.errMsg == "scanQRCode:permission denied") {
                that.$dialog.error("没有权限");
              }
            }
          });
        }
      });
    }
  }
};
exports.default = _default;