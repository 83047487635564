"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _distribution = require("@/api/distribution");

var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));

var _permission = require("@/utils/permission");

var _validate = require("@/utils/validate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = {
  name: 'Index',
  data: function data() {
    return {
      promoterForm: {},
      loading: true,
      rules: {
        brokerageFuncStatus: [{
          required: true,
          message: '请选择是否启用分销',
          trigger: 'change'
        }],
        storeBrokerageRatio: [{
          required: true,
          message: '请输入一级返佣比例',
          trigger: 'blur'
        }],
        storeBrokerageTwo: [{
          required: true,
          message: '请输入二级返佣比例',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getDetal();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    channelInputLimit: function channelInputLimit(e) {
      var key = e.key; // 不允许输入'e'和'.'

      if (key === 'e' || key === '.') {
        e.returnValue = false;
        return false;
      }

      return true;
    },
    getDetal: function getDetal() {
      var _this = this;

      this.loading = true;
      (0, _distribution.configApi)().then(function (res) {
        _this.loading = false;
        _this.promoterForm = res;
        _this.promoterForm.storeBrokerageIsBubble = res.storeBrokerageIsBubble.toString();
        _this.promoterForm.brokerageFuncStatus = res.brokerageFuncStatus.toString();
        _this.promoterForm.brokerageBindind = res.brokerageBindind.toString();
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (selfUtil.Add(_this2.promoterForm.storeBrokerageRatio, _this2.promoterForm.storeBrokerageTwo) > 100) return _this2.$message.warning('返佣比例相加不能超过100%');
          _this2.loading = true;
          (0, _distribution.configUpdateApi)(_this2.promoterForm).then(function (res) {
            _this2.loading = false;

            _this2.$message.success('提交成功'); // this.$modalSure('提交成功，是否自动下架商户低于此佣金比例的商品').then(() => {
            //   productCheckApi().then(({ message }) => {
            //     this.$message.success(message)
            //   }).catch(({ message }) => {
            //     this.$message.error(message)
            //   })
            // })

          }).catch(function (err) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    })
  }
};
exports.default = _default;