"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _statisticsData = _interopRequireDefault(require("../components/statisticsData"));

var _Loading = _interopRequireDefault(require("../components/Loading"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  name: "OrderIndex",
  components: {
    Loading: _Loading.default,
    statisticsData: _statisticsData.default
  },
  props: {},
  data: function data() {
    return {
      census: [],
      list: [],
      where: {
        page: 1,
        limit: 10
      },
      loaded: false,
      loading: false
    };
  },
  created: function created() {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/assets/js/media_750'));
    });
  },
  mounted: function mounted() {
    var _this = this;

    this.getIndex();
    this.getList();
    this.$scroll(this.$refs.container, function () {
      !_this.loading && _this.getList();
    });
  },
  methods: {
    getIndex: function getIndex() {
      var _this2 = this;

      (0, _order.orderStatisticsApi)().then(function (res) {
        _this2.census = res;
      }, function (err) {
        _this2.$dialog.message(err.message);
      });
    },
    getList: function getList() {
      var _this3 = this;

      if (this.loading || this.loaded) return;
      this.loading = true;
      (0, _order.statisticsDataApi)(this.where).then(function (res) {
        _this3.loading = false;
        _this3.loaded = res.length < _this3.where.limit;

        _this3.list.push.apply(_this3.list, res);

        _this3.where.page = _this3.where.page + 1;
      }, function (error) {
        _this3.$dialog.message(error.message);
      }, 300);
    }
  }
};
exports.default = _default;