"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _validate = require("@/utils/validate");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "index",
  data: function data() {
    var _this = this;

    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.pram.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    var JavaInfo = JSON.parse(_jsCookie.default.get('JavaInfo'));
    return {
      password: '',
      JavaInfo: JSON.parse(_jsCookie.default.get('JavaInfo')),
      pram: {
        account: JavaInfo.account,
        pwd: null,
        repwd: null,
        realName: JavaInfo.realName,
        id: JavaInfo.id
      },
      roleList: [],
      rules: {
        account: [{
          required: true,
          message: '请填管理员账号',
          trigger: ['blur', 'change']
        }],
        pwd: [{
          required: true,
          message: '请填管理员密码',
          trigger: ['blur', 'change']
        }],
        repwd: [{
          required: true,
          message: '确认密码密码',
          validator: validatePass,
          trigger: ['blur', 'change']
        }],
        realName: [{
          required: true,
          message: '管理员姓名',
          trigger: ['blur', 'change']
        }]
      }
    };
  },
  methods: {
    close: function close(formName) {
      this.$refs[formName].resetFields();
    },
    handlerSubmit: (0, _validate.Debounce)(function (formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          systemAdminApi.adminUpdate(_this2.pram).then(function (data) {
            _this2.$message.success('提交成功');

            _this2.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }),
    handlerPwdInput: function handlerPwdInput(val) {
      var _this3 = this;

      if (!val) {
        this.rules.pwd = [];
        this.rules.repwd = [];
        return;
      }

      this.rules.pwd = [{
        required: true,
        message: '请填管理员密码',
        trigger: ['blur', 'change']
      }, {
        min: 6,
        max: 20,
        message: '长度6-20个字符',
        trigger: ['blur', 'change']
      }];
      this.rules.repwd = [{
        required: true,
        message: '两次输入密码不一致',
        validator: function validator(rule, value, callback) {
          if (value === '') {
            callback(new Error('两次输入密码不一致!'));
          } else if (value !== _this3.pram.pwd) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        },
        trigger: ['blur', 'change']
      }];
    }
  }
};
exports.default = _default;