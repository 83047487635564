"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  default: function _default(h, conf, key) {
    return conf.__slot__[key];
  }
};
exports.default = _default2;