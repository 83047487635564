"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

var _systemConfig = require("@/api/systemConfig.js");

var _validate = require("@/utils/validate");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "integralconfig",
  components: {
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      isShow: false,
      isCreate: 0,
      editData: {},
      formId: 109
    };
  },
  mounted: function mounted() {
    this.getFormInfo();
  },
  methods: {
    resetForm: function resetForm(formValue) {
      this.editData = {};
    },
    handlerSubmit: (0, _validate.Debounce)(function (data) {
      var _this = this;

      var tempArr = [];

      for (var key in data) {
        var obj = {};
        obj.name = key;
        obj.title = key;
        obj.value = data[key];
        tempArr.push(obj);
      }

      var _pram = {
        'fields': tempArr,
        'id': this.formId,
        'sort': 0,
        'status': true
      };
      (0, _systemConfig.configSaveForm)(_pram).then(function (res) {
        _this.getFormInfo();

        _this.$message.success('操作成功');
      });
    }),
    // 获取表单详情
    getFormInfo: function getFormInfo() {
      var _this2 = this;

      (0, _systemConfig.configInfo)({
        id: this.formId
      }).then(function (res) {
        _this2.isShow = false;
        _this2.editData = res;
        _this2.isCreate = 1;
        setTimeout(function () {
          // 让表单重复渲染待编辑数据
          _this2.isShow = true;
        }, 80);
      });
    }
  }
};
exports.default = _default;