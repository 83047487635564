"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _combineEdit = _interopRequireDefault(require("./combineEdit"));

var systemGroupDataApi = _interopRequireWildcard(require("@/api/systemGroupData.js"));

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "combineDataList"
  components: {
    edit: _combineEdit.default
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        gid: null,
        keywords: null,
        status: null,
        // 1=开启 2=关闭
        page: 1,
        pageSize: this.$constants.page.limit[0]
      },
      editDataConfig: {
        visible: false,
        isCreate: 0,
        // 0=create 1=edit
        editData: {}
      },
      formConf: {
        fields: []
      },
      dataList: {
        list: [],
        total: 0
      },
      formMark: 0
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig();
    this.listPram.gid = this.formData.id;
    this.handlerGetListData(this.listPram);
  },
  methods: {
    handlerSearch: function handlerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this = this;

      // 获取列表数据
      systemGroupDataApi.groupDataList(pram).then(function (data) {
        var _selfList = [];
        data.list.forEach(function (_lItem) {
          _lItem.value = JSON.parse(_lItem.value);
          var _fields = _lItem.value.fields;
          var _rowData = {};

          _fields.map(function (item) {
            _rowData[item.name] = item.value;
          });

          _rowData.id = _lItem.id;
          _rowData.sort = _lItem.sort;
          _rowData.status = _lItem.status;

          _selfList.push(_rowData);
        });
        _this.dataList.list = _selfList;
        _this.dataList.total = data.total;
      });
    },
    handlerGetFormConfig: function handlerGetFormConfig() {
      var _this2 = this;

      // 获取表单配置后生成table列
      var _pram = {
        id: this.formData.formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this2.formMark = parseInt(data.id);
        _this2.formConf = JSON.parse(data.content);
      });
    },
    handlerOpenEditData: function handlerOpenEditData(rowData, isCreate) {
      this.editDataConfig.editData = rowData;
      this.editDataConfig.isCreate = isCreate;
      this.editDataConfig.visible = true;
    },
    handlerHideDia: function handlerHideDia() {
      this.handlerGetListData(this.listPram);
      this.editDataConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this3 = this;

      this.$confirm('确实删除当前数据', '提示').then(function () {
        systemGroupDataApi.groupDataDelete(rowData).then(function (data) {
          _this3.$message.success('删除数据成功');

          _this3.handlerHideDia();
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
exports.default = _default;