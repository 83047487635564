"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var operationRouter = {
  path: '/operation',
  component: _layout.default,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'setting',
    name: 'setting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/setting'));
      });
    },
    meta: {
      title: '系统设置',
      icon: 'clipboard'
    }
  }, {
    path: 'notification',
    name: 'notification',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/notification'));
      });
    },
    meta: {
      title: '消息通知',
      icon: 'clipboard'
    }
  }, {
    path: 'onePass',
    name: 'onePass',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sms/smsConfig'));
      });
    },
    meta: {
      title: '一号通',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority'));
      });
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/identityManager'));
        });
      },
      name: 'identityManager',
      meta: {
        title: '角色管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/adminList'));
        });
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }, {
      path: 'promiseRules',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/permissionRules'));
        });
      },
      name: 'promiseRules',
      meta: {
        title: '权限规则',
        icon: ''
      }
    }]
  }, {
    path: 'systemSms',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sms'));
      });
    },
    name: 'systemSms',
    meta: {
      title: '短信设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsConfig'));
        });
      },
      name: 'SmsConfig',
      meta: {
        title: '短信账户',
        noCache: true
      }
    }, {
      path: 'template',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsTemplate'));
        });
      },
      name: 'SmsTemplate',
      meta: {
        title: '短信模板',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'pay',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsPay'));
        });
      },
      name: 'SmsPay',
      meta: {
        title: '短信购买',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'message',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsMessage'));
        });
      },
      name: 'SmsMessage',
      meta: {
        title: '短信开关',
        noCache: true
      }
    }]
  }, {
    path: 'deliverGoods',
    name: 'deliverGoods',
    alwaysShow: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods'));
      });
    },
    meta: {
      title: '发货设置',
      roles: ['admin']
    },
    children: [{
      path: 'takeGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/takeGoods'));
        });
      },
      name: 'takeGoods',
      meta: {
        title: '提货设置',
        noCache: true,
        roles: ['admin']
      },
      children: [{
        path: 'deliveryAddress',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/takeGoods/deliveryAddress'));
          });
        },
        name: 'deliveryAddress',
        meta: {
          title: '提货点',
          icon: ''
        }
      }, {
        path: 'collateOrder',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/takeGoods/collateOrder'));
          });
        },
        name: 'collateOrder',
        meta: {
          title: '核销订单',
          icon: ''
        }
      }, {
        path: 'collateUser',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/takeGoods/collateUser'));
          });
        },
        name: 'collateUser',
        meta: {
          title: '核销员',
          icon: ''
        }
      }]
    }, {
      path: 'freightSet',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/freightSet'));
        });
      },
      name: 'freightSet',
      meta: {
        title: '运费模板',
        noCache: true
      }
    }]
  }]
};
var _default = operationRouter; //collate

exports.default = _default;