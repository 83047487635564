var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "order-index" },
    [
      _c(
        "div",
        { staticClass: "header acea-row" },
        [
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/javaMobile/orderList/unPaid" },
            },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.census.unpaidCount)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("待付款")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/javaMobile/orderList/notShipped" },
            },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.census.unshippedCount)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("待发货")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/javaMobile/orderList/spike" },
            },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.census.receivedCount)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("待收货")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/javaMobile/orderList/toBeWrittenOff" },
            },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.census.verificationCount)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("待核销")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/javaMobile/orderList/refunding" },
            },
            [
              _c("div", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.census.refundCount)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("退款")]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list acea-row" },
          [
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: { to: "/javaMobile/orderStatisticsDetail/price/today" },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.todayPrice)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("今日成交额")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: {
                  to: "/javaMobile/orderStatisticsDetail/price/yesterday",
                },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.proPrice)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("昨日成交额")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: { to: "/javaMobile/orderStatisticsDetail/price/month" },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.monthPrice)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("本月成交额")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: { to: "/javaMobile/orderStatisticsDetail/order/today" },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.todayCount)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("今日订单数")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: {
                  to: "/javaMobile/orderStatisticsDetail/order/yesterday",
                },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.proCount)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("昨日订单数")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "item",
                attrs: { to: "/javaMobile/orderStatisticsDetail/order/month" },
              },
              [
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.census.monthCount)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("本月订单数")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("statistics-data", { attrs: { list: _vm.list } }),
      _vm._v(" "),
      _c("Loading", { attrs: { loaded: _vm.loaded, loading: _vm.loading } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "iconfont icon-shujutongji" }),
      _vm._v("数据统计\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }