"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var service = _axios.default.create({
  timeout: 40000
});

service.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  var res = response;

  if (res.status !== 200 && res.status !== 401) {
    Message({
      message: res.data.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject();
  } else {
    return res.data;
  }
}, function (error) {});
var _default = service;
exports.default = _default;