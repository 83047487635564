import { render, staticRenderFns } from "./addClerk.vue?vue&type=template&id=3bbb0178&scoped=true&"
import script from "./addClerk.vue?vue&type=script&lang=js&"
export * from "./addClerk.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbb0178",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\beeMailAdmin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bbb0178')) {
      api.createRecord('3bbb0178', component.options)
    } else {
      api.reload('3bbb0178', component.options)
    }
    module.hot.accept("./addClerk.vue?vue&type=template&id=3bbb0178&scoped=true&", function () {
      api.rerender('3bbb0178', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/systemSetting/deliverGoods/takeGoods/collateUser/addClerk.vue"
export default component.exports