var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _vm.checkPermi(["admin:product:tabs:headers"])
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.seachList },
                      model: {
                        value: _vm.tableFrom.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "type", $$v)
                        },
                        expression: "tableFrom.type",
                      },
                    },
                    _vm._l(_vm.headeNum, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: {
                          label: item.name + "(" + item.count + ")",
                          name: item.type.toString(),
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container mt-1" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth mr20",
                            attrs: {
                              options: _vm.merCateList,
                              props: _vm.props,
                              clearable: "",
                              size: "small",
                            },
                            on: { change: _vm.seachList },
                            model: {
                              value: _vm.tableFrom.cateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "cateId", $$v)
                              },
                              expression: "tableFrom.cateId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入商品名称，关键字，商品ID",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keywords", $$v)
                                },
                                expression: "tableFrom.keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:product:list"],
                                    expression: "['admin:product:list']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.seachList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/store/list/creatProduct" } } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:product:save"],
                          expression: "['admin:product:save']",
                        },
                      ],
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("添加商品")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:product:save"],
                      expression: "['admin:product:save']",
                    },
                  ],
                  staticClass: "mr10",
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.onCopy },
                },
                [_vm._v("商品采集")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:export:excel:product"],
                      expression: "['admin:export:excel:product']",
                    },
                  ],
                  attrs: { size: "small", icon: "el-icon-upload2" },
                  on: { click: _vm.exports },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "sort-change": _vm.changeSort },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品分类：" } },
                              _vm._l(
                                props.row.cateValues.split(","),
                                function (item, index) {
                                  return _c(
                                    "span",
                                    { key: index, staticClass: "mr10" },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "市场价：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.otPrice))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "成本价：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.cost))])]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(_vm._s(props.row.collectCount)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "虚拟销量：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.ficti))])]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  prop: "storeName",
                  "min-width": "300",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "商品售价",
                  "min-width": "90",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sales",
                  label: "兑换量",
                  "min-width": "90",
                  align: "center",
                  sortable: "custom",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  label: "库存",
                  "min-width": "90",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: "排序",
                  "min-width": "70",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "添加时间",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.addTime))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "80", fixed: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm.checkPermi([
                          "admin:product:up",
                          "admin:product:down",
                        ])
                          ? [
                              _c("el-switch", {
                                attrs: {
                                  disabled: Number(_vm.tableFrom.type) > 2,
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.isShow,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "isShow", $$v)
                                  },
                                  expression: "scope.row.isShow",
                                },
                              }),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/store/list/creatProduct/" +
                                  scope.row.id +
                                  "/1",
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:product:info"],
                                    expression: "['admin:product:info']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("详情")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/store/list/creatProduct/" + scope.row.id,
                              },
                            },
                          },
                          [
                            _vm.tableFrom.type !== "5" &&
                            _vm.tableFrom.type !== "1"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["admin:product:update"],
                                        expression: "['admin:product:update']",
                                      },
                                    ],
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5"
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:product:restore"],
                                    expression: "['admin:product:restore']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRestore(
                                      scope.row.id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复商品")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:product:delete"],
                                expression: "['admin:product:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  _vm.tableFrom.type
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.tableFrom.type === "5"
                                  ? "删除"
                                  : "加入回收站"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "taoBaoModal",
          attrs: {
            title: "复制淘宝、天猫、京东、苏宁",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "1200px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("tao-bao", { on: { handleCloseMod: _vm.handleCloseMod } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }