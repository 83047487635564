var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "OrderCancellation" }, [
        _c("div", { staticClass: "header" }),
        _vm._v(" "),
        _c("div", { staticClass: "whiteBg" }, [
          _c("div", { staticClass: "input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.verify_code,
                  expression: "verify_code",
                },
              ],
              attrs: { type: "number", placeholder: "请输入核销码" },
              domProps: { value: _vm.verify_code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.verify_code = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bnt", on: { click: _vm.storeCancellation } },
            [_vm._v("立即核销")]
          ),
        ]),
        _vm._v(" "),
        _vm.isWeixin
          ? _c("div", { staticClass: "scan" }, [
              _c("img", {
                attrs: { src: require("../../../assets/imgs/scan.gif") },
                on: { click: _vm.openQRCode },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.orderInfo
        ? _c("WriteOff", {
            attrs: { iShidden: _vm.iShidden, orderInfo: _vm.orderInfo },
            on: { cancel: _vm.cancel, confirm: _vm.confirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }