"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formConfig = _interopRequireDefault(require("@/views/maintain/formConfig"));

var systemGroupApi = _interopRequireWildcard(require("@/api/systemGroup"));

var _validate = require("@/utils/validate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "combinedDataEdit"
  components: {
    formConfigList: _formConfig.default
  },
  props: {
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      editPram: {
        formId: null,
        info: null,
        name: null,
        id: null
      },
      selectedFormConfigData: {},
      selectFormDialogConfig: {
        visible: false
      }
    };
  },
  mounted: function mounted() {
    this.handlerInitEditData();
  },
  methods: {
    handlerInitEditData: function handlerInitEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
          id = _this$editData.id,
          name = _this$editData.name,
          info = _this$editData.info,
          formId = _this$editData.formId,
          createTime = _this$editData.createTime,
          updateTime = _this$editData.updateTime;
      this.editPram.id = id;
      this.editPram.name = name;
      this.editPram.info = info;
      this.editPram.formId = formId;
    },
    handlerSelectedRowData: function handlerSelectedRowData(rowData) {
      this.selectedFormConfigData = rowData;
      this.editPram.formId = this.selectedFormConfigData.id;
      this.selectFormDialogConfig.visible = false;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this = this;

      this.$refs[form].validate(function (result) {
        if (!result) return;
        _this.isCreate === 0 ? _this.handlerSave(_this.editPram) : _this.handlerEdit(_this.editPram);
      });
    }),
    handlerSave: function handlerSave(pram) {
      var _this2 = this;

      systemGroupApi.groupSave(pram).then(function (data) {
        _this2.$message.success('添加组合数据成功');

        _this2.$emit('hideDialog');
      });
    },
    handlerEdit: function handlerEdit(pram) {
      var _this3 = this;

      systemGroupApi.groupEdit(pram).then(function (data) {
        _this3.$message.success('编辑组合数据成功');

        _this3.$emit('hideDialog');
      });
    }
  }
};
exports.default = _default;