"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var roleApi = _interopRequireWildcard(require("@/api/role.js"));

var _edit = _interopRequireDefault(require("./edit"));

var _permission = require("@/utils/permission");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = {
  // name: "index"
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listData: {
        list: []
      },
      listPram: {
        createTime: null,
        updateTime: null,
        level: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        roleName: null,
        rules: null,
        status: null
      },
      menuList: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleGetRoleList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this = this;

      this.$confirm('确认删除当前数据').then(function () {
        roleApi.delRole(rowData).then(function (data) {
          _this.$message.success('删除数据成功');

          _this.handleGetRoleList();
        });
      });
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;

      roleApi.getRoleList(this.listPram).then(function (data) {
        _this2.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      isCreate === 1 ? this.editDialogConfig.editData = editDate : this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleGetRoleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleGetRoleList(this.listPram);
    },
    //修改状态
    handleStatusChange: function handleStatusChange(row) {
      var _this3 = this;

      roleApi.updateRoleStatus(row).then(function (res) {
        _this3.$message.success('更新状态成功');

        _this3.handleGetRoleList();
      });
    },
    resetQuery: function resetQuery() {
      this.listPram.roleName = '';
      this.handleGetRoleList();
    }
  }
};
exports.default = _default;