var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container" }, [
    _c("div", { staticClass: "public-wrapper" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conter" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "item acea-row row-between-wrapper" },
            [
              _c("div", { staticClass: "data" }, [_vm._v(_vm._s(item.time))]),
              _vm._v(" "),
              _c("div", { staticClass: "browse" }, [
                _vm._v(_vm._s(item.count)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "turnover" }, [
                _vm._v(_vm._s(item.price)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "iconfont icon-xiangxishuju" }),
      _vm._v("详细数据\n    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav acea-row row-between-wrapper" }, [
      _c("div", { staticClass: "data" }, [_vm._v("日期")]),
      _vm._v(" "),
      _c("div", { staticClass: "browse" }, [_vm._v("订单数")]),
      _vm._v(" "),
      _c("div", { staticClass: "turnover" }, [_vm._v("成交额")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }