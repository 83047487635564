"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDrawingList = getDrawingList;
exports.getFormConf = getFormConf;
exports.getFormConfSelf = getFormConfSelf;
exports.getIdGlobal = getIdGlobal;
exports.getTreeNodeId = getTreeNodeId;
exports.saveDrawingList = saveDrawingList;
exports.saveFormConf = saveFormConf;
exports.saveIdGlobal = saveIdGlobal;
exports.saveTreeNodeId = saveTreeNodeId;
var DRAWING_ITEMS = 'drawingItems';
var DRAWING_ITEMS_VERSION = '1.1';
var DRAWING_ITEMS_VERSION_KEY = 'DRAWING_ITEMS_VERSION';
var DRAWING_ID = 'idGlobal';
var TREE_NODE_ID = 'treeNodeId';
var FORM_CONF = 'formConf';

function getDrawingList() {
  // 加入缓存版本的概念，保证缓存数据与程序匹配
  var version = localStorage.getItem(DRAWING_ITEMS_VERSION_KEY);

  if (version !== DRAWING_ITEMS_VERSION) {
    localStorage.setItem(DRAWING_ITEMS_VERSION_KEY, DRAWING_ITEMS_VERSION);
    saveDrawingList([]);
    return null;
  }

  var str = localStorage.getItem(DRAWING_ITEMS);
  if (str) return JSON.parse(str);
  return null;
}

function saveDrawingList(list) {
  localStorage.setItem(DRAWING_ITEMS, JSON.stringify(list));
}

function getIdGlobal() {
  var str = localStorage.getItem(DRAWING_ID);
  if (str) return parseInt(str, 10);
  return 100;
}

function saveIdGlobal(id) {
  localStorage.setItem(DRAWING_ID, "".concat(id));
}

function getTreeNodeId() {
  var str = localStorage.getItem(TREE_NODE_ID);
  if (str) return parseInt(str, 10);
  return 100;
}

function saveTreeNodeId(id) {
  localStorage.setItem(TREE_NODE_ID, "".concat(id));
}

function getFormConf() {
  var str = localStorage.getItem(FORM_CONF);
  if (str) return JSON.parse(str);
  return null;
}

function saveFormConf(obj) {
  localStorage.setItem(FORM_CONF, JSON.stringify(obj));
}
/**
 * 根据自己的需求获取配置的表单信息
 */


function getFormConfSelf() {
  var formConfig = localStorage.getItem(FORM_CONF);
  formConfig = JSON.parse(formConfig);
  var formItemConfig = localStorage.getItem(DRAWING_ITEMS);
  if (!formConfig && !formItemConfig) return 'Error';
  formItemConfig = JSON.parse(formItemConfig);
  formConfig.fields = formItemConfig;
  return formConfig;
}