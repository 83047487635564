"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));

var systemGroupDataApi = _interopRequireWildcard(require("@/api/systemGroupData.js"));

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

var _validate = require("@/utils/validate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "combineEdit"
  components: {
    parser: _Parser.default
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit

    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      formConf: {
        fields: []
      },
      selfForm: {
        sort: 0,
        status: 0
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig();
    this.handlerInitEditData();
  },
  methods: {
    handlerInitEditData: function handlerInitEditData() {
      var _this$editData = this.editData,
          sort = _this$editData.sort,
          status = _this$editData.status;
      this.selfForm.sort = sort;
      this.selfForm.status = status;
    },
    handlerGetFormConfig: function handlerGetFormConfig() {
      var _this = this;

      // 获取表单配置后生成table列
      var _pram = {
        id: this.formData.formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      this.isCreate === 0 ? this.handlerSave(formValue) : this.handlerEdit(formValue);
    }),
    handlerSave: function handlerSave(formValue) {
      var _this2 = this;

      var _pram = this.buildFormPram(formValue);

      systemGroupDataApi.groupDataSave(_pram).then(function (data) {
        _this2.$message.success('添加数据成功');

        _this2.$emit('hideDialog');
      });
    },
    handlerEdit: function handlerEdit(formValue) {
      var _this3 = this;

      var _pram = this.buildFormPram(formValue);

      systemGroupDataApi.groupDataEdit(_pram, this.editData.id).then(function (data) {
        _this3.$message.success('编辑数据成功');

        _this3.$emit('hideDialog');
      });
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        gid: this.formData.id,
        form: {
          fields: [],
          id: this.formData.formId,
          sort: this.selfForm.sort,
          status: this.selfForm.status
        }
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.form.fields = _fields;
      return _pram;
    }
  }
};
exports.default = _default;