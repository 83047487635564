var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSearch,
                  expression: "showSearch",
                },
              ],
              ref: "queryForm",
              attrs: { model: _vm.queryParams, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "菜单名称", prop: "menuName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入菜单名称",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.queryParams.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "name", $$v)
                      },
                      expression: "queryParams.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "menuType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "菜单状态",
                        clearable: "",
                        size: "small",
                      },
                      model: {
                        value: _vm.queryParams.menuType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "menuType", $$v)
                        },
                        expression: "queryParams.menuType",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        plain: "",
                        icon: "el-icon-sort",
                        size: "mini",
                      },
                      on: { click: _vm.toggleExpandAll },
                    },
                    [_vm._v("展开/折叠")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.refreshTable
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.menuList,
                    "row-key": "id",
                    "default-expand-all": _vm.isExpandAll,
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                    "header-cell-style": { fontWeight: "bold" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "菜单名称",
                      "show-overflow-tooltip": true,
                      width: "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "icon",
                      label: "图标",
                      align: "center",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                class: "el-icon-" + scope.row.icon,
                                staticStyle: { "font-size": "20px" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      244404100
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序", width: "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "perms",
                      label: "权限标识",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "component",
                      label: "组件路径",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "isShow", label: "状态", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: scope.row.isShow ? "" : "danger",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(scope.row.isShow ? "显示" : "隐藏")
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2038216616
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      align: "center",
                      prop: "createTime",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parseTime(scope.row.createTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3078210614
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "menuType", label: "类型", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.menuType == "M"
                                ? _c("span", { staticClass: "type_tag one" }, [
                                    _vm._v("目录"),
                                  ])
                                : scope.row.menuType == "C"
                                ? _c("span", { staticClass: "type_tag two" }, [
                                    _vm._v("菜单"),
                                  ])
                                : _c(
                                    "span",
                                    {
                                      staticClass: "type_tag three",
                                      attrs: { type: "info" },
                                    },
                                    [_vm._v("按钮")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4965977
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:system:menu:info"],
                                      expression: "['admin:system:menu:info']",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:system:menu:add"],
                                      expression: "['admin:system:menu:add']",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-plus",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("新增")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:system:menu:delete"],
                                      expression:
                                        "['admin:system:menu:delete']",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1405516454
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.open,
                width: "680px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.open = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级菜单" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.menuOptions,
                                  normalizer: _vm.normalizer,
                                  "show-count": true,
                                  placeholder: "选择上级菜单",
                                },
                                model: {
                                  value: _vm.form.pid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pid", $$v)
                                  },
                                  expression: "form.pid",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单类型", prop: "menuType" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.menuType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "menuType", $$v)
                                    },
                                    expression: "form.menuType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "M" } }, [
                                    _vm._v("目录"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "C" } }, [
                                    _vm._v("菜单"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "A" } }, [
                                    _vm._v("按钮"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.form.menuType != "A"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "菜单图标" } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请选择菜单图标",
                                          },
                                          model: {
                                            value: _vm.form.icon,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "icon", $$v)
                                            },
                                            expression: "form.icon",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-circle-plus-outline",
                                            },
                                            on: { click: _vm.addIcon },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单名称", prop: "menuName" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入菜单名称" },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "显示排序", prop: "sort" } },
                            [
                              _c("el-input-number", {
                                attrs: { "controls-position": "right", min: 0 },
                                model: {
                                  value: _vm.form.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sort", $$v)
                                  },
                                  expression: "form.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.form.menuType !== "A"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "component" } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content:
                                              "访问的组件路径，如：`system/user/index`，默认在`views`目录下",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              组件路径\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入组件路径" },
                                    model: {
                                      value: _vm.form.component,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "component", $$v)
                                      },
                                      expression: "form.component",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.form.menuType != "M"
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入权限标识",
                                      maxlength: "100",
                                    },
                                    model: {
                                      value: _vm.form.perms,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "perms", $$v)
                                      },
                                      expression: "form.perms",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content:
                                              "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              权限字符\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content:
                                          "选择隐藏则路由将不会出现在侧边栏，但仍然可以访问",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              显示状态\n            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isShow,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isShow", $$v)
                                    },
                                    expression: "form.isShow",
                                  },
                                },
                                _vm._l(_vm.showStatus, function (item) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: item.value,
                                      attrs: { label: item.value },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:system:menu:update"],
                          expression: "['admin:system:menu:update']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }