var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.isShow
            ? _c("zb-parser", {
                attrs: {
                  "form-id": _vm.formId,
                  "is-create": _vm.isCreate,
                  "edit-data": _vm.editData,
                },
                on: { submit: _vm.handlerSubmit, resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }