var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "priceChange", class: _vm.change === true ? "on" : "" },
      [
        _c("div", { staticClass: "priceTitle" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.status === 0 || _vm.status === 2
                  ? _vm.orderInfo.refundStatus === 1
                    ? "立即退款"
                    : "一键改价"
                  : _vm.status === 1
                  ? "订单备注"
                  : "拒绝原因"
              ) +
              "\n      "
          ),
          _c("i", {
            staticClass: "el-icon-circle-close iconfonts",
            on: { click: _vm.close },
          }),
        ]),
        _vm._v(" "),
        _vm.status === 0 || _vm.status === 2
          ? _c("div", { staticClass: "listChange" }, [
              _vm.orderInfo.refundStatus === 0
                ? _c(
                    "div",
                    { staticClass: "item acea-row row-between-wrapper" },
                    [
                      _c("div", [_vm._v("商品总价(¥)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "money" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.orderInfo.totalPrice)
                        ),
                        _c("span", { staticClass: "iconfont icon-suozi" }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.refundStatus === 0
                ? _c(
                    "div",
                    { staticClass: "item acea-row row-between-wrapper" },
                    [
                      _c("div", [_vm._v("原始邮费(¥)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "money" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.orderInfo.payPostage)
                        ),
                        _c("span", { staticClass: "iconfont icon-suozi" }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.refundStatus === 0
                ? _c(
                    "div",
                    { staticClass: "item acea-row row-between-wrapper" },
                    [
                      _c("div", [_vm._v("实际支付(¥)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "money" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.price,
                              expression: "price",
                            },
                          ],
                          class: _vm.focus === true ? "on" : "",
                          attrs: { type: "text" },
                          domProps: { value: _vm.price },
                          on: {
                            focus: _vm.priceChange,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.price = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.refundStatus === 1
                ? _c(
                    "div",
                    { staticClass: "item acea-row row-between-wrapper" },
                    [
                      _c("div", [_vm._v("实际支付(¥)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "money" }, [
                        _vm._v("\n          " + _vm._s(_vm.orderInfo.payPrice)),
                        _c("span", { staticClass: "iconfont icon-suozi" }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.refundStatus === 1
                ? _c(
                    "div",
                    { staticClass: "item acea-row row-between-wrapper" },
                    [
                      _c("div", [_vm._v("退款金额(¥)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "money" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.refundPrice,
                              expression: "refundPrice",
                            },
                          ],
                          class: _vm.focus === true ? "on" : "",
                          attrs: { type: "text" },
                          domProps: { value: _vm.refundPrice },
                          on: {
                            focus: _vm.priceChange,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.refundPrice = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm.status === 3
          ? _c("div", { staticClass: "listChange" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reason,
                    expression: "reason",
                  },
                ],
                attrs: { placeholder: "请填写退款原因", maxlength: "100" },
                domProps: { value: _vm.reason },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.reason = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "listChange" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.remark,
                    expression: "remark",
                  },
                ],
                attrs: {
                  placeholder: _vm.orderInfo.remark
                    ? _vm.orderInfo.remark
                    : "请填写备注信息...",
                  maxlength: "100",
                },
                domProps: { value: _vm.remark },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.remark = $event.target.value
                  },
                },
              }),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "modify", on: { click: _vm.save } }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.orderInfo.refundStatus === 0 ||
                  _vm.status === 1 ||
                  _vm.status === 3
                  ? "立即提交"
                  : "确认退款"
              ) +
              "\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.change === true,
          expression: "change === true",
        },
      ],
      staticClass: "maskModel",
      on: {
        touchmove: function ($event) {
          $event.preventDefault()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }