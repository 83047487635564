"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var maintainRouter = {
  path: '/maintain',
  component: _layout.default,
  redirect: '/maintain/devconfiguration/configCategory',
  name: 'maintain',
  meta: {
    title: '维护',
    icon: 'clipboard'
  },
  children: [{
    path: 'devconfiguration',
    name: 'devconfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/maintain'));
      });
    },
    meta: {
      title: '开发配置',
      icon: 'clipboard'
    },
    children: [{
      path: 'configCategory',
      name: 'configCategory',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/maintain/devconfig/configCategroy'));
        });
      },
      meta: {
        title: '配置分类',
        icon: 'clipboard'
      }
    }, {
      path: 'combineddata',
      name: 'combineddata',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/maintain/devconfig/combinedData'));
        });
      },
      meta: {
        title: '组合数据',
        icon: 'clipboard'
      }
    }, {
      path: 'formConfig',
      name: 'formConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/maintain/formConfig/index'));
        });
      },
      meta: {
        title: '表单配置',
        icon: 'clipboard'
      }
    }]
  }, {
    path: 'user',
    name: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/maintain/user'));
      });
    },
    meta: {
      title: '个人中心',
      icon: 'clipboard'
    },
    hidden: true
  }, {
    path: 'picture',
    name: 'picture',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/maintain/picture'));
      });
    },
    meta: {
      title: '素材管理',
      icon: 'clipboard'
    },
    hidden: false
  }, {
    path: 'authCRMEB',
    name: 'authCRMEB',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/maintain/authCRMEB'));
      });
    },
    meta: {
      title: '授权',
      icon: 'clipboard'
    },
    hidden: false
  }, //sensitiveList
  {
    path: 'logistics',
    name: 'Logistics',
    alwaysShow: true,
    redirect: '/logistics/cityList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/maintain'));
      });
    },
    meta: {
      title: '物流设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'cityList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/maintain/logistics/cityList'));
        });
      },
      name: 'cityList',
      meta: {
        title: '城市数据',
        icon: ''
      }
    }, {
      path: 'companyList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/maintain/logistics/companyList'));
        });
      },
      name: 'companyList',
      meta: {
        title: '物流公司',
        icon: ''
      }
    }]
  }]
};
var _default = maintainRouter;
exports.default = _default;