"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function vModel(self, dataObject, defaultValue) {
  dataObject.props.value = defaultValue;

  dataObject.on.input = function (val) {
    self.$emit('input', val);
  };
}

var componentChild = {};
/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */

var slotsFiles = require.context('./slots', true, /\.js$/);

var keys = slotsFiles.keys() || [];
keys.forEach(function (key) {
  var tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = slotsFiles(key).default;
  componentChild[tag] = value;
});
var _default = {
  render: function render(h) {
    var _this = this;

    var dataObject = {
      attrs: {},
      props: {},
      on: {},
      style: {}
    };
    var confClone = JSON.parse(JSON.stringify(this.conf));
    var children = [];
    var childObjs = componentChild[confClone.__config__.tag];

    if (childObjs) {
      Object.keys(childObjs).forEach(function (key) {
        var childFunc = childObjs[key];

        if (confClone.__slot__ && confClone.__slot__[key]) {
          children.push(childFunc(h, confClone, key));
        }
      });
    }

    Object.keys(confClone).forEach(function (key) {
      var val = confClone[key];

      if (key === '__vModel__') {
        vModel(_this, dataObject, confClone.__config__.defaultValue);
      } else if (dataObject[key]) {
        dataObject[key] = _objectSpread(_objectSpread({}, dataObject[key]), val);
      } else {
        dataObject.attrs[key] = val;
      }
    });
    delete dataObject.attrs.__config__;
    delete dataObject.attrs.__slot__;
    return h(this.conf.__config__.tag, dataObject, children);
  },
  props: ['conf']
};
exports.default = _default;