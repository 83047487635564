var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.checkPermi([
        "admin:statistics:home:chart:order",
        "admin:statistics:home:chart:order:week",
        "admin:statistics:home:chart:order:month",
        "admin:statistics:home:chart:order:year",
      ])
        ? _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { san: "24" } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "dashboard-console-visit",
                      attrs: { bordered: false },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "acea-row row-between-wrapper" },
                          [
                            _c("div", { staticClass: "acea-row row-middle" }, [
                              _c("div", { staticClass: "header_title" }, [
                                _vm._v("订单统计"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "checkTime" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "ivu-mr-8",
                                    on: { change: _vm.radioChange },
                                    model: {
                                      value: _vm.visitDate,
                                      callback: function ($$v) {
                                        _vm.visitDate = $$v
                                      },
                                      expression: "visitDate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "last30" } },
                                      [_vm._v("30天")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "week" } },
                                      [_vm._v("周")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "month" } },
                                      [_vm._v("月")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "year" } },
                                      [_vm._v("年")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h4", [_vm._v("订单量趋势")]),
                      _vm._v(" "),
                      _vm.info
                        ? _c("echarts-from", {
                            ref: "visitChart",
                            attrs: {
                              yAxisData: _vm.yAxisData,
                              seriesData: _vm.series,
                              xAxis: _vm.xAxis,
                              legendData: _vm.legendData,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }