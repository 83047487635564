"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));

var _render = _interopRequireDefault(require("@/components/FormGenerator/components/render/render.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ruleTrigger = {
  'el-input': 'blur',
  'el-input-number': 'blur',
  'el-select': 'change',
  'el-radio-group': 'change',
  'el-checkbox-group': 'change',
  'el-cascader': 'change',
  'el-time-picker': 'change',
  'el-date-picker': 'change',
  'el-rate': 'change'
};

function renderFrom(h) {
  var formConfCopy = this.formConfCopy;
  return h("el-row", {
    "attrs": {
      "gutter": formConfCopy.gutter
    }
  }, [h("el-form", (0, _babelHelperVueJsxMergeProps.default)([{
    "attrs": {
      "size": formConfCopy.size,
      "label-position": formConfCopy.labelPosition,
      "disabled": formConfCopy.disabled,
      "label-width": "".concat(formConfCopy.labelWidth, "px")
    },
    "ref": formConfCopy.formRef
  }, {
    "props": {
      model: this[formConfCopy.formModel]
    }
  }, {
    "attrs": {
      "rules": this[formConfCopy.formRules]
    }
  }]), [renderFormItem.call(this, h, formConfCopy.fields), formConfCopy.formBtns && formBtns.call(this, h)])]);
}

function formBtns(h) {
  return h("el-col", [h("el-form-item", {
    "attrs": {
      "size": 'mini'
    }
  }, [h("el-button", {
    "attrs": {
      "type": 'primary'
    },
    "on": {
      "click": this.submitForm
    }
  }, ["\u63D0\u4EA4"])])]);
}

function renderFormItem(h, elementList) {
  var _this = this;

  return elementList.map(function (scheme) {
    var config = scheme.__config__;
    var layout = layouts[config.layout];

    if (layout) {
      return layout.call(_this, h, scheme);
    }

    throw new Error("\u6CA1\u6709\u4E0E".concat(config.layout, "\u5339\u914D\u7684layout"));
  });
}

function renderChildren(h, scheme) {
  var config = scheme.__config__;
  if (!Array.isArray(config.children)) return null;
  return renderFormItem.call(this, h, config.children);
}

function setValue(event, config, scheme) {
  this.$set(config, 'defaultValue', event);
  this.$set(this[this.formConf.formModel], scheme.__vModel__, event);
}

function buildListeners(scheme) {
  var _this2 = this;

  var config = scheme.__config__;
  var methods = this.formConf.__methods__ || {};
  var listeners = {}; // 给__methods__中的方法绑定this和event

  Object.keys(methods).forEach(function (key) {
    listeners[key] = function (event) {
      return methods[key].call(_this2, event);
    };
  }); // 响应 render.js 中的 vModel $emit('input', val)

  listeners.input = function (event) {
    return setValue.call(_this2, event, config, scheme);
  };

  return listeners;
}

var layouts = {
  colFormItem: function colFormItem(h, scheme) {
    var config = scheme.__config__;
    var listeners = buildListeners.call(this, scheme);
    var labelWidth = config.labelWidth ? "".concat(config.labelWidth, "px") : null;
    if (config.showLabel === false) labelWidth = '0';

    if (config.tips && !config.tipsIsLink) {
      return h("el-col", {
        "attrs": {
          "span": config.span
        }
      }, [h("el-form-item", {
        "attrs": {
          "label-width": labelWidth,
          "prop": scheme.__vModel__,
          "label": config.showLabel ? config.label : ''
        }
      }, [h("el-tooltip", {
        "attrs": {
          "effect": "dark",
          "placement": "top-start"
        },
        "style": "padding:10px 5px 0 0;"
      }, ["\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("i", {
        "class": "el-icon-warning-outline"
      }), "\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("div", {
        "slot": "content",
        "style": "max-width:400px;"
      }, [config.tipsDesc]), "\xA0 \xA0 \xA0 \xA0 \xA0"]), h(_render.default, {
        "attrs": {
          "conf": scheme
        },
        "on": _objectSpread({}, listeners)
      })])]);
    } else if (config.tips && config.tipsIsLink) {
      return h("el-col", {
        "attrs": {
          "span": config.span
        }
      }, [h("el-form-item", {
        "attrs": {
          "label-width": labelWidth,
          "prop": scheme.__vModel__,
          "label": config.showLabel ? config.label : ''
        }
      }, [h("el-tooltip", {
        "attrs": {
          "effect": "dark",
          "placement": "top-start"
        },
        "style": "padding:10px 5px 0 0;"
      }, ["\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("i", {
        "class": "el-icon-warning-outline"
      }), "\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("div", {
        "slot": "content",
        "style": "max-width:400px;"
      }, [h("a", {
        "attrs": {
          "href": config.tipsLink,
          "target": "_blank"
        }
      }, [config.tipsDesc])]), "\xA0 \xA0 \xA0 \xA0 \xA0"]), h(_render.default, {
        "attrs": {
          "conf": scheme
        },
        "on": _objectSpread({}, listeners)
      })])]);
    } else {
      return h("el-col", {
        "attrs": {
          "span": config.span
        }
      }, [h("el-form-item", {
        "attrs": {
          "label-width": labelWidth,
          "prop": scheme.__vModel__,
          "label": config.showLabel ? config.label : ''
        }
      }, [h(_render.default, {
        "attrs": {
          "conf": scheme
        },
        "on": _objectSpread({}, listeners)
      })])]);
    }
  },
  rowFormItem: function rowFormItem(h, scheme) {
    var child = renderChildren.apply(this, arguments);

    if (scheme.type === 'flex') {
      child = h("el-row", {
        "attrs": {
          "type": scheme.type,
          "justify": scheme.justify,
          "align": scheme.align
        }
      }, [child]);
    }

    return h("el-col", {
      "attrs": {
        "span": scheme.span
      }
    }, [h("el-row", {
      "attrs": {
        "gutter": scheme.gutter
      }
    }, [child])]);
  }
};
var _default = {
  components: {
    render: _render.default
  },
  props: {
    formConf: {
      type: Object,
      required: true
    },
    formEditData: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var _this3 = this,
        _data;

    if (this.isEdit) {
      // 初始化待编辑数据
      this.formConf.fields.forEach(function (conf) {
        // 设置现有的数据
        var hasValueForEdit = _this3.formEditData[conf.__vModel__];

        if (hasValueForEdit) {
          conf.__config__.defaultValue = hasValueForEdit;
        } // 如果是el-select标签 判断数据后改变实现默认选中效果


        if (conf.__config__.tag === 'el-select' || conf.__config__.tag === 'el-radio-group') {
          var perValue = conf.__slot__.options.filter(function (option) {
            return option.value == _this3.formEditData[conf.__vModel__];
          });

          if (perValue.length > 0) {
            // 有表单数据
            conf.__config__.defaultValue = perValue[0].value;
          }
        }
      });
    }

    var data = (_data = {
      formConfCopy: JSON.parse(JSON.stringify(this.formConf))
    }, _defineProperty(_data, this.formConf.formModel, {}), _defineProperty(_data, this.formConf.formRules, {}), _data);
    this.initFormData(data.formConfCopy.fields, data[this.formConf.formModel]);
    this.buildRules(data.formConfCopy.fields, data[this.formConf.formRules]);
    return data;
  },
  methods: {
    initFormData: function initFormData(componentList, formData) {
      var _this4 = this;

      componentList.forEach(function (cur) {
        var config = cur.__config__;
        if (cur.__vModel__) formData[cur.__vModel__] = config.defaultValue;
        if (config.children) _this4.initFormData(config.children, formData);
      });
    },
    buildRules: function buildRules(componentList, rules) {
      var _this5 = this;

      componentList.forEach(function (cur) {
        var config = cur.__config__;

        if (Array.isArray(config.regList)) {
          if (config.required) {
            var required = {
              required: config.required,
              message: cur.placeholder
            };

            if (Array.isArray(config.defaultValue)) {
              required.type = 'array';
              required.message = "\u8BF7\u81F3\u5C11\u9009\u62E9\u4E00\u4E2A".concat(config.label);
            }

            required.message === undefined && (required.message = "".concat(config.label, "\u4E0D\u80FD\u4E3A\u7A7A"));
            config.regList.push(required);
          }

          rules[cur.__vModel__] = config.regList.map(function (item) {
            item.pattern && (item.pattern = eval(item.pattern));
            item.trigger = ruleTrigger && ruleTrigger[config.tag];
            return item;
          });
        }

        if (config.children) _this5.buildRules(config.children, rules);
      });
    },
    resetForm: function resetForm() {
      this.$emit('resetForm', this.formConf);
      this.formConfCopy = JSON.parse(JSON.stringify(this.formConf));
      this.$refs[this.formConf.formRef].resetFields();
    },
    submitForm: function submitForm() {
      var _this6 = this;

      this.$refs[this.formConf.formRef].validate(function (valid) {
        if (!valid) return false; // 触发sumit事件

        _this6.$emit('submit', _this6[_this6.formConf.formModel]);

        return true;
      });
    }
  },
  render: function render(h) {
    return renderFrom.call(this, h);
  }
};
exports.default = _default;