"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _EditorImage = _interopRequireDefault(require("./components/EditorImage"));

var _plugins = _interopRequireDefault(require("./plugins"));

var _toolbar = _interopRequireDefault(require("./toolbar"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Tinymce',
  components: {
    editorImage: _EditorImage.default
  },
  props: {
    id: {
      type: String,
      default: function _default() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 400
    }
  },
  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN'
      }
    };
  },
  computed: {
    language: function language() {
      // return this.languageTypeList[this.$store.getters.language]
      return this.languageTypeList['zh'];
    }
  },
  watch: {
    value: function value(val) {
      var _this2 = this;

      if (!this.hasChange && this.hasInit) {
        this.$nextTick(function () {
          return window.tinymce.get(_this2.tinymceId).setContent(val || '');
        });
      }
    },
    language: function language() {
      var _this3 = this;

      this.destroyTinymce();
      this.$nextTick(function () {
        return _this3.initTinymce();
      });
    }
  },
  mounted: function mounted() {
    this.initTinymce();
  },
  activated: function activated() {
    this.initTinymce();
  },
  deactivated: function deactivated() {
    this.destroyTinymce();
  },
  destroyed: function destroyed() {
    this.destroyTinymce();
  },
  methods: {
    initTinymce: function initTinymce() {
      var _this4 = this;

      var _this = this;

      window.tinymce.init({
        language: this.language,
        selector: "#".concat(this.tinymceId),
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : _toolbar.default,
        menubar: this.menubar,
        plugins: _plugins.default,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true,
        // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: function init_instance_callback(editor) {
          if (_this.value) {
            editor.setContent(_this.value);
          }

          _this.hasInit = true;
          editor.on('NodeChange Change KeyUp SetContent', function () {
            _this4.hasChange = true;

            _this4.$emit('input', editor.getContent());
          });
        },
        setup: function setup(editor) {
          editor.on('FullscreenStateChanged', function (e) {
            _this.fullscreen = e.state;
          });
        }
      });
    },
    destroyTinymce: function destroyTinymce() {
      var tinymce = window.tinymce.get(this.tinymceId);

      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen');
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent: function setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent: function getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK: function imageSuccessCBK(arr) {
      var _this5 = this;

      var _this = this;

      arr.forEach(function (v) {
        if (_this5.getFileType(v) == "video") {
          window.tinymce.get(_this.tinymceId).insertContent("<video class=\"wscnph\" src=\"".concat(v, "\" controls muted></video>"));
        } else {
          window.tinymce.get(_this.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v, "\" />"));
        }
      });
    },
    getFileType: function getFileType(fileName) {
      // 后缀获取
      var suffix = ""; // 获取类型结果

      var result = "";

      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      } // fileName无后缀返回 false


      if (!suffix) {
        return false;
      }

      suffix = suffix.toLocaleLowerCase(); // 图片格式

      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"]; // 进行图片匹配

      result = imglist.find(function (item) {
        return item === suffix;
      });

      if (result) {
        return "image";
      } // 匹配 视频


      var videolist = ["mp4", "m2v", "mkv", "rmvb", "wmv", "avi", "flv", "mov", "m4v"];
      result = videolist.find(function (item) {
        return item === suffix;
      });

      if (result) {
        return "video";
      } // 其他 文件类型


      return "other";
    }
  }
};
exports.default = _default2;