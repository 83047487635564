var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "page_title" }, [_vm._v("授权证书申请")]),
          _vm._v(" "),
          _c("div", { staticClass: "page_desc" }, [
            _vm._v(
              "您的支持是我们不断进步的动力，商业授权更多是一个保障和附加的增值服务，让您优先享受新版本的强大功能和安全保障"
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "企业名称", prop: "company_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写您的企业名称" },
                    model: {
                      value: _vm.form.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_name", $$v)
                      },
                      expression: "form.company_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "企业域名", prop: "domain_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入域名，格式：baidu.com" },
                    model: {
                      value: _vm.form.domain_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "domain_name", $$v)
                      },
                      expression: "form.domain_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单号", prop: "order_id" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入您在淘宝或小程序购买的源码订单号",
                    },
                    model: {
                      value: _vm.form.order_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "order_id", $$v)
                      },
                      expression: "form.order_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "负责人电话" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "授权产品" } },
                [_c("el-input", { attrs: { value: "java", disabled: "" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "验证码", prop: "captcha" } },
                [
                  _c(
                    "div",
                    { staticClass: "captcha" },
                    [
                      _c("el-input", {
                        ref: "username",
                        staticStyle: { width: "218px" },
                        attrs: {
                          "prefix-icon": "el-icon-message",
                          placeholder: "验证码",
                          name: "username",
                          type: "text",
                          tabindex: "3",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.form.captcha,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "captcha", $$v)
                          },
                          expression: "form.captcha",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "imgs",
                          on: {
                            click: function ($event) {
                              return _vm.getCaptcha()
                            },
                          },
                        },
                        [_c("img", { attrs: { src: _vm.captchs } })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("form")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }