"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _icon = _interopRequireDefault(require("../utils/icon.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var originList = _icon.default.map(function (name) {
  return "el-icon-".concat(name);
});

var _default = {
  inheritAttrs: false,
  props: ['current'],
  data: function data() {
    return {
      iconList: originList,
      active: null,
      key: ''
    };
  },
  watch: {
    key: function key(val) {
      if (val) {
        this.iconList = originList.filter(function (name) {
          return name.indexOf(val) > -1;
        });
      } else {
        this.iconList = originList;
      }
    }
  },
  methods: {
    onOpen: function onOpen() {
      this.active = this.current;
      this.key = '';
    },
    onClose: function onClose() {},
    onSelect: function onSelect(icon) {
      this.active = icon;
      this.$emit('select', icon);
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default;