"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
var _default = {
  name: "index",
  data: function data() {
    return {
      pictureType: 'maintain'
    };
  },
  components: {
    UploadIndex: _index.default
  }
};
exports.default = _default;