"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var marketingRouter = {
  path: '/marketing',
  component: _layout.default,
  redirect: '/coupon/list',
  name: 'Marketing',
  meta: {
    title: '营销',
    icon: 'clipboard'
  },
  children: [{
    path: 'coupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/coupon/index'));
      });
    },
    name: 'Coupon',
    meta: {
      title: '优惠券',
      icon: ''
    },
    children: [{
      path: 'template',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponTemplate/index'));
        });
      },
      name: 'couponTemplate',
      hidden: true,
      meta: {
        title: '优惠券模板',
        icon: ''
      }
    }, {
      path: 'list/save/:id?',
      name: 'couponAdd',
      meta: {
        title: '优惠劵添加',
        noCache: true,
        activeMenu: "/marketing/coupon/list"
      },
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/list/creatCoupon'));
        });
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/list/index'));
        });
      },
      name: 'List',
      meta: {
        title: '优惠券列表',
        icon: ''
      }
    }, {
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/record/index'));
        });
      },
      name: 'Record',
      meta: {
        title: '领取记录',
        icon: ''
      }
    }]
  }, {
    path: 'bargain',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/bargain/index'));
      });
    },
    name: 'Bargain',
    meta: {
      title: '砍价管理',
      icon: ''
    },
    alwaysShow: true,
    hidden: true,
    children: [{
      path: 'bargainGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainGoods/index'));
        });
      },
      name: 'bargainGoods',
      meta: {
        title: '砍价商品',
        icon: ''
      }
    }, {
      path: 'creatBargain/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainGoods/creatBargain'));
        });
      },
      name: 'creatBargain',
      meta: {
        title: '砍价商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/bargain/bargainGoods"
      }
    }, {
      path: 'bargainList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainList/index'));
        });
      },
      name: 'bargainList',
      meta: {
        title: '砍价列表',
        icon: ''
      }
    }]
  }, {
    path: 'groupBuy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/groupBuy/index'));
      });
    },
    name: 'groupBuy',
    meta: {
      title: '拼团管理',
      icon: ''
    },
    hidden: true,
    children: [{
      path: 'groupGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupGoods/index'));
        });
      },
      name: 'groupGoods',
      meta: {
        title: '拼团商品',
        icon: ''
      }
    }, {
      path: 'creatGroup/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupGoods/creatGroup'));
        });
      },
      name: 'creatGroup',
      meta: {
        title: '拼团商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/groupBuy/groupGoods"
      }
    }, {
      path: 'groupList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupList/index'));
        });
      },
      name: 'groupList',
      meta: {
        title: '拼团列表',
        icon: ''
      }
    }]
  }, {
    path: 'seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    name: 'Seckill',
    meta: {
      title: '秒杀管理',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillConfig/index'));
        });
      },
      name: 'SeckillConfig',
      meta: {
        title: '秒杀配置',
        icon: ''
      }
    }, {
      path: 'list/:timeId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillList/index'));
        });
      },
      name: 'SeckillList',
      meta: {
        title: '秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/list"
      }
    }, {
      path: 'creatSeckill/:name?/:timeId?/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillList/creatSeckill'));
        });
      },
      name: 'CreatSeckill',
      meta: {
        title: '添加秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/list"
      }
    }]
  }, {
    path: 'integral',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/integral/index'));
      });
    },
    name: 'Integral',
    meta: {
      title: '积分',
      icon: ''
    },
    children: [{
      path: 'integralconfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/config/index'));
        });
      },
      name: 'integralConfig',
      meta: {
        title: '积分配置',
        icon: ''
      }
    }, {
      path: 'integrallog',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/integralLog/index'));
        });
      },
      name: 'integralLog',
      meta: {
        title: '积分日志',
        icon: ''
      }
    }]
  }]
};
var _default = marketingRouter;
exports.default = _default;