var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "ivu-mb mb10 dashboard-console-visit" },
            [
              _c(
                "el-card",
                { attrs: { bordered: false, "dis-hover": "" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", { staticClass: "acea-row row-middle" }, [
                      _c("div", { staticClass: "header_title" }, [
                        _vm._v("用户统计"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.infoList
                    ? _c("echarts-from", {
                        ref: "userChart",
                        attrs: {
                          echartsTitle: _vm.line,
                          xAxis: _vm.xAxis,
                          seriesData: _vm.series,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }