"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: []
    };
  },
  methods: {
    modalPicTap: function modalPicTap(tit) {
      var _this = this;

      this.$modalUpload(function (img) {
        var arr = [];
        if (img.length > 10) return this.$message.warning("最多选择10张图片！");
        img.map(function (item) {
          arr.push(item.sattDir);
        }); // console.log(arr);

        _this.$emit('successCBK', arr);
      }, tit, 'content');
    }
  }
};
exports.default = _default;