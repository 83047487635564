var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "promoterForm",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.promoterForm,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "brokerageFuncStatus" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("分销启用：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "商城分销功能开启关闭",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.promoterForm.brokerageFuncStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.promoterForm, "brokerageFuncStatus", $$v)
                        },
                        expression: "promoterForm.brokerageFuncStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "storeBrokerageQuota" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("满额分销最低金额：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "满额分销满足金额开通分销权限",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      placeholder: "满额分销满足金额开通分销权限",
                      min: -1,
                      step: 1,
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        return _vm.channelInputLimit($event)
                      },
                    },
                    model: {
                      value: _vm.promoterForm.storeBrokerageQuota,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "storeBrokerageQuota", $$v)
                      },
                      expression: "promoterForm.storeBrokerageQuota",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "brokerageBindind" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("分销关系绑定：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "所有用户”指所有没有上级推广人的用户，“新用户”指新注册的用户",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.promoterForm.brokerageBindind,
                        callback: function ($$v) {
                          _vm.$set(_vm.promoterForm, "brokerageBindind", $$v)
                        },
                        expression: "promoterForm.brokerageBindind",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("所有用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("新用户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "storeBrokerageIsBubble" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("分销气泡：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "基础商品详情页分销气泡功能开启关闭",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.promoterForm.storeBrokerageIsBubble,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.promoterForm,
                            "storeBrokerageIsBubble",
                            $$v
                          )
                        },
                        expression: "promoterForm.storeBrokerageIsBubble",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "storeBrokerageRatio" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("一级返佣比例：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "订单交易成功后给上级返佣的比例0 - 100,例:5 = 反订单金额的5%",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      "step-strictly": "",
                      min: 0,
                      max: 100,
                      placeholder:
                        "订单交易成功后给上级返佣的比例0 - 100,例:5 = 反订单金额的5%",
                    },
                    model: {
                      value: _vm.promoterForm.storeBrokerageRatio,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "storeBrokerageRatio", $$v)
                      },
                      expression: "promoterForm.storeBrokerageRatio",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("%")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "storeBrokerageTwo" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("二级返佣比例：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "订单交易成功后给上级返佣的比例0 ~ 100,例:5 = 反订单金额的5%",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      "step-strictly": "",
                      min: 0,
                      max: 100,
                      placeholder:
                        "订单交易成功后给上级返佣的比例0 ~ 100,例:5 = 反订单金额的5%",
                    },
                    model: {
                      value: _vm.promoterForm.storeBrokerageTwo,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "storeBrokerageTwo", $$v)
                      },
                      expression: "promoterForm.storeBrokerageTwo",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("%")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "userExtractMinPrice" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("提现最低金额：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "用户提现最低金额",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: { min: 0, step: 1, placeholder: "用户提现最低金额" },
                    model: {
                      value: _vm.promoterForm.userExtractMinPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "userExtractMinPrice", $$v)
                      },
                      expression: "promoterForm.userExtractMinPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "userExtractBank" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("提现银行卡：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "提现银行卡，每个银行换行",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "提现银行卡，每个银行换行",
                    },
                    model: {
                      value: _vm.promoterForm.userExtractBank,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "userExtractBank", $$v)
                      },
                      expression: "promoterForm.userExtractBank",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "extractTime" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [_vm._v("冻结时间：")]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "佣金冻结时间(天)",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: { min: 0, placeholder: "佣金冻结时间(天)" },
                    model: {
                      value: _vm.promoterForm.extractTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.promoterForm, "extractTime", $$v)
                      },
                      expression: "promoterForm.extractTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:retail:spread:manage:set"],
                          expression: "['admin:retail:spread:manage:set']",
                        },
                      ],
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("promoterForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }