"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _marketing = require("@/api/marketing");

var _settings = require("@/settings");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CouponUser',
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        'receive': '自己领取',
        'send': '后台发送',
        'give': '满赠',
        'new': '新人',
        'buy': '买赠送'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '未使用',
        1: '已使用',
        2: '已过期'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      imgList: [],
      tableFromIssue: {
        page: 1,
        limit: 20,
        uid: '',
        name: '',
        status: ''
      },
      issueData: {
        data: [],
        total: 0
      },
      loading: false,
      options: []
    };
  },
  mounted: function mounted() {
    this.getIssueList();
  },
  methods: {
    remoteMethod: function remoteMethod(query) {
      var _this = this;

      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this.loading = false;
          (0, _user.userListApi)({
            keywords: query,
            page: 1,
            limit: 10
          }).then(function (res) {
            _this.options = res.list;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    seachList: function seachList() {
      this.tableFromIssue.page = 1;
      this.getIssueList();
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this2 = this;

      this.Loading = true;
      (0, _marketing.couponUserListApi)(this.tableFromIssue).then(function (res) {
        _this2.issueData.data = res.list;
        _this2.issueData.total = res.total; // this.issueData.data.map((item) => {
        //   this.imgList.push(item.user.avatar)
        // })

        _this2.Loading = false;
      }).catch(function (res) {
        _this2.Loading = false;

        _this2.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    }
  }
};
exports.default = _default;