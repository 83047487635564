var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-FromGen" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              _vm._l(_vm.leftComponents, function (item, listIndex) {
                return _c(
                  "div",
                  { key: listIndex },
                  [
                    _c(
                      "div",
                      { staticClass: "components-title" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "component" },
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.title) +
                            "\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "components-draggable",
                        attrs: {
                          list: item.list,
                          group: {
                            name: "componentsGroup",
                            pull: "clone",
                            put: false,
                          },
                          clone: _vm.cloneComponent,
                          draggable: ".components-item",
                          sort: false,
                        },
                        on: { end: _vm.onEnd },
                      },
                      _vm._l(item.list, function (element, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "components-item",
                            on: {
                              click: function ($event) {
                                return _vm.addComponent(element)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "components-body" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": element.__config__.tagIcon,
                                  },
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(element.__config__.label) +
                                    "\n                "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center-board" },
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c(
                "el-form",
                { ref: "selfForm", attrs: { inline: "", model: _vm.selfForm } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请填写名称",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称" },
                        model: {
                          value: _vm.selfForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.selfForm, "name", $$v)
                          },
                          expression: "selfForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "描述",
                        prop: "info",
                        rules: [
                          {
                            required: true,
                            message: "请填写描述",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "描述" },
                        model: {
                          value: _vm.selfForm.info,
                          callback: function ($$v) {
                            _vm.$set(_vm.selfForm, "info", $$v)
                          },
                          expression: "selfForm.info",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:system:form:update"],
                              expression: "['admin:system:form:update']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handlerSaveJSON("selfForm")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-scrollbar",
            { staticClass: "center-scrollbar" },
            [
              _c(
                "el-row",
                {
                  staticClass: "center-board-row",
                  attrs: { gutter: _vm.formConf.gutter },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: _vm.formConf.size,
                        "label-position": _vm.formConf.labelPosition,
                        disabled: _vm.formConf.disabled,
                        "label-width": _vm.formConf.labelWidth + "px",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "drawing-board",
                          attrs: {
                            list: _vm.drawingList,
                            animation: 340,
                            group: "componentsGroup",
                          },
                        },
                        _vm._l(_vm.drawingList, function (element, index) {
                          return _c("draggable-item", {
                            key: element.renderKey,
                            attrs: {
                              "drawing-list": _vm.drawingList,
                              element: element,
                              index: index,
                              "active-id": _vm.activeId,
                              "form-conf": _vm.formConf,
                            },
                            on: {
                              activeItem: _vm.activeFormItem,
                              copyItem: _vm.drawingItemCopy,
                              deleteItem: _vm.drawingItemDelete,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.drawingList.length,
                              expression: "!drawingList.length",
                            },
                          ],
                          staticClass: "empty-info",
                        },
                        [
                          _vm._v(
                            "\n              从左侧拖入或点选组件进行表单设计\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("right-panel", {
        attrs: {
          "active-data": _vm.activeData,
          "form-conf": _vm.formConf,
          "show-field": !!_vm.drawingList.length,
        },
        on: { "tag-change": _vm.tagChange },
      }),
      _vm._v(" "),
      _c("form-drawer", {
        attrs: {
          visible: _vm.drawerVisible,
          "form-data": _vm.formData,
          size: "100%",
          "generate-conf": _vm.generateConf,
        },
        on: {
          "update:visible": function ($event) {
            _vm.drawerVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c("json-drawer", {
        attrs: {
          size: "60%",
          visible: _vm.jsonDrawerVisible,
          "json-str": JSON.stringify(_vm.formData),
        },
        on: {
          "update:visible": function ($event) {
            _vm.jsonDrawerVisible = $event
          },
          refresh: _vm.refreshJson,
        },
      }),
      _vm._v(" "),
      _c("code-type-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          title: "选择生成类型",
          "show-file-name": _vm.showFileName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          confirm: _vm.generate,
        },
      }),
      _vm._v(" "),
      _c("input", { attrs: { id: "copyNode", type: "hidden" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-wrapper" }, [
      _c("div", { staticClass: "logo" }, [_c("span", [_vm._v("CRMEB")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }