var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lottie-bg" }, [
      _c("div", [_vm._v("222进来了22222")]),
      _vm._v(" "),
      _c("div", { attrs: { id: "lottie" } }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: {
            src: require("@/assets/imgs/live-logo.gif"),
            rel: "preload",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }