var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deliver-goods" }, [
    _c("header", [
      _c("div", { staticClass: "order-num acea-row row-between-wrapper" }, [
        _c("div", { staticClass: "num line1" }, [
          _vm._v("订单号：" + _vm._s(_vm.orderId)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "name line1" }, [
          _c("span", { staticClass: "iconfont iconios-contact" }),
          _vm._v(_vm._s(_vm.delivery.nikeName) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n        " + _vm._s(_vm.delivery.realName)),
          _c("span", { staticClass: "phone" }, [
            _vm._v(_vm._s(_vm.delivery.userPhone)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.delivery.userAddress))]),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "item acea-row row-between-wrapper" }, [
        _c("div", [_vm._v("发货方式")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mode acea-row row-middle row-right" },
          _vm._l(_vm.types, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "goods",
                class: _vm.active === index ? "on" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeType(item, index)
                  },
                },
              },
              [
                _vm._v("\n          " + _vm._s(item.title)),
                _c("span", { staticClass: "iconfont icon-xuanzhong2" }),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 0,
              expression: "active === 0",
            },
          ],
          staticClass: "list",
        },
        [
          _c("div", { staticClass: "item acea-row row-between-wrapper" }, [
            _c("div", [_vm._v("发货方式")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.expressCode,
                    expression: "expressCode",
                  },
                ],
                staticClass: "mode",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.expressCode = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("选择快递公司"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.express, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.code } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("span", { staticClass: "iconfont icon-up" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item acea-row row-between-wrapper" }, [
            _c("div", [_vm._v("快递单号")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.expressNumber,
                  expression: "expressNumber",
                },
              ],
              staticClass: "mode",
              attrs: { type: "text", placeholder: "填写快递单号" },
              domProps: { value: _vm.expressNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.expressNumber = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 1,
              expression: "active === 1",
            },
          ],
          staticClass: "list",
        },
        [
          _c("div", { staticClass: "item acea-row row-between-wrapper" }, [
            _c("div", [_vm._v("送货人")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deliveryName,
                  expression: "deliveryName",
                },
              ],
              staticClass: "mode",
              attrs: { type: "text", placeholder: "填写送货人" },
              domProps: { value: _vm.deliveryName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.deliveryName = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item acea-row row-between-wrapper" }, [
            _c("div", [_vm._v("送货电话")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deliveryTel,
                  expression: "deliveryTel",
                },
              ],
              staticClass: "mode",
              attrs: { type: "text", placeholder: "填写送货电话" },
              domProps: { value: _vm.deliveryTel },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.deliveryTel = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { height: "1.2rem" } }),
    _vm._v(" "),
    _c("div", { staticClass: "confirm", on: { click: _vm.saveInfo } }, [
      _vm._v("确认提交"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [
      _c("img", { attrs: { src: require("../../../assets/imgs/line.jpg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }